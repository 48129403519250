/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux';

const ADD_CAMPANHA = 'ADD_CAMPANHA';
const INCREMENT_CAMPANHA = 'INCREMENT_CAMPANHA';

export function addcampanha(campanha) {
  return {
    type: ADD_CAMPANHA,
    campanha,
  };
}

export function incrementcampanha(campanha) {
  return {
    type: INCREMENT_CAMPANHA,
    campanha
  };
}

const defaultcampanhas = [];

function campanhas(state = defaultcampanhas, action) {
  switch (action.type) {
    case ADD_CAMPANHA:
      return [
        ...state,
        {
          name: action.campanha,
          views: 1
        }
      ];
    case INCREMENT_CAMPANHA:
      const campanha = state.find((b) => action.campanha === b.name);
      const campanhas = state.filter((b) => action.campanha !== b.name);
      return [
        ...campanhas,
        {
          ...campanha,
          views: campanha.views + 1
        }
      ];
    default:
      return state;
  }
}

const campanhaApp = combineReducers({
  campanhas
});

export default campanhaApp;

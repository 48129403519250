/* eslint-disable react/prop-types */
import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const SimpleBackdrop = (props) => {
  const { open, texto } = props;
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <Box
          display="flex"
          justifyContent="flex-start"
          p={1}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <p>{texto}</p>
            </Grid>
            <Grid item xs={12} md={12}>
              <CircularProgress color="inherit" />
            </Grid>
          </Grid>
        </Box>
      </Backdrop>
    </div>
  );
};

SimpleBackdrop.propTypes = {
  open: PropTypes.any.isRequired,
};

export default SimpleBackdrop;

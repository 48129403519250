import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CampanhaListView from 'src/views/campanha/CampanhaListView';
import DashboardView from 'src/views/reports/DashboardView';
import DashboardVDI from 'src/views/reports/DashboardVDI';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import NovaCampanha from 'src/views/campanha/CampanhaListView/NovaCampanha';
import EditarCampanha from 'src/views/campanha/CampanhaListView/EditarCampanha';
import DetalheCampanha from 'src/views/campanha/CampanhaListView/DetalheCampanha';
import ClientesListView from 'src/views/cliente/ClienteListView';
import TitulosListView from 'src/views/titulo/TituloListView';
import PedidoListView from 'src/views/pedido/PedidoListView';
import NovoPedidoView from 'src/views/novopedido/NovoPedidoView';
import LoginView from './views/auth/LoginView';
import PrimeiroAcesso from './views/auth/PrimeiroAcesso';
import EsqueciMinhaSenha from './views/auth/EsqueciMinhaSenha';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'perfil', element: <AccountView /> },
      { path: 'campanha', element: <CampanhaListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'vdi', element: <DashboardVDI /> },
      { path: 'novacampanha', element: <NovaCampanha /> },
      { path: 'editarcampanha', element: <EditarCampanha /> },
      { path: 'detalhecampanha', element: <DetalheCampanha /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'clientes', element: <ClientesListView /> },
      { path: 'titulos', element: <TitulosListView /> },
      { path: 'pedidos', element: <PedidoListView /> },
      { path: 'novopedido', element: <NovoPedidoView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <LoginView /> },
      { path: '/primeiroacesso', element: <PrimeiroAcesso /> },
      { path: '/esqueciminhasenha', element: <EsqueciMinhaSenha /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/dashboard', element: <Navigate to="/app/dashboard" /> },
      // { path: '/novacampanha', element: <Navigate to="/app/novacampanha" /> },
      { path: '*', element: <Navigate to="/InicioView" /> }
    ]
  }
];

export default routes;

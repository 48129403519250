import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  Box,
  Button,
  TextField,
  makeStyles
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable, { TableFilterList } from 'mui-datatables';
import { format } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import BackDrop from '../../../components/BackDrop';
import DialogTelaCheia from '../../../components/DialogTelaCheia';
import MensagemAlerta from '../../../components/MensagemAlerta';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(1),
    // paddingTop: theme.spacing(1)
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  afastamento: {
    paddingTop: theme.spacing(3)
  },
  afastamentodireita: {
    paddingRight: theme.spacing(3)
  }
}));

// eslint-disable-next-line react/prop-types
const CustomChip = ({ label, onDelete }) => {
  return (
    <Chip
      variant="outlined"
      color="secondary"
      label={label}
      onDelete={onDelete}
    />
  );
};

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

const TituloDataTable = () => {
  const classes = useStyles();
  const [isFetching, setisFetching] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [state, setState] = useState({
    open: false,
    texto: '',
  });
  const [clientes, setClientes] = React.useState([]);
  const [codcliente, setCodCliente] = React.useState(null);
  const formatadata = (data) => format(data, "yyyy'-'MM'-'dd");
  const [titulos, setTitulos] = useState([]);
  // const [isFetching, setisFetching] = useState(false);
  const [datainicial, setDataInicial] = React.useState(new Date());
  const [datafinal, setDataFinal] = React.useState(new Date());
  // eslint-disable-next-line max-len
  const [datainicialformatada, setdatainicialformatada] = React.useState(formatadata(new Date()));
  const [datafinalformatada, setdatafinalformatada] = React.useState(formatadata(new Date()));

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const handleCloseMensagem = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const handleDataFinal = (e) => {
    const resultado = formatadata(e);
    setdatafinalformatada(resultado);
    setDataFinal(e);
  };
  const handleDataInicial = (e) => {
    const resultado = formatadata(e);
    setdatainicialformatada(resultado);
    setDataInicial(e);
  };

  const consultarTitulos = async () => {
    let encontrou = false;
    const usuariologado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    setisFetching(true);
    if (codcliente !== null) {
      api.post(
        '/api-pedidos/tituloclientedata',
        {
          codrep: usuariologado.codigo,
          codcliente: codcliente.codigo,
          dtinicio: datainicialformatada,
          dtfim: datafinalformatada
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then((response) => {
        setisFetching(false);
        if (response.data.data === null) {
          encontrou = false;
        } else {
          setTitulos(response.data.data);
          encontrou = true;
          handleDialogOpen(true);
        }
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);

          // console.log(error.response.headers);
        } else if (error.request) {
          setState({
            ...state,
            texto: 'Consulta só disponivel com sinal de dados disponivel',
            open: true
          });
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        }
      });

      if (encontrou === false) {
        setisFetching(true);
        api.post(
          '/api-pedidos/vendedorinternotituloclientedata',
          {
            email: usuariologado.email,
            codcliente: codcliente.codigo,
            dtinicio: datainicialformatada,
            dtfim: datafinalformatada
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        ).then((response) => {
          setisFetching(false);
          if (response.data.data === null) {
            encontrou = false;
          } else {
            setTitulos(response.data.data);
            encontrou = true;
            handleDialogOpen(true);
          }
        }).catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);

            // console.log(error.response.headers);
          } else if (error.request) {
            setState({
              ...state,
              texto: 'Consulta só disponivel com sinal de dados disponivel',
              open: true
            });
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          }
        });
      }
    } else {
      api.post(
        '/api-pedidos/titulorepresentantedata',
        {
          codrep: usuariologado.codigo,
          dtinicio: datainicialformatada,
          dtfim: datafinalformatada
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then((response) => {
        setisFetching(false);
        if (response.data.data === null) {
          encontrou = false;
        } else {
          setTitulos(response.data.data);
          encontrou = true;
          handleDialogOpen(true);
        }
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);

          // console.log(error.response.headers);
        } else if (error.request) {
          setState({
            ...state,
            texto: 'Consulta só disponivel com sinal de dados disponivel',
            open: true
          });
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        }
      });

      if (encontrou === false) {
        api.post(
          '/api-pedidos/titulorepresentanteinternodata',
          {
            email: usuariologado.email,
            dtinicio: datainicialformatada,
            dtfim: datafinalformatada
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        ).then((response) => {
          setisFetching(false);
          if (response.data.data === null) {
            encontrou = false;
          } else {
            setTitulos(response.data.data);
            encontrou = true;
            handleDialogOpen(true);
          }
        }).catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);

            // console.log(error.response.headers);
          } else if (error.request) {
            setState({
              ...state,
              texto: 'Consulta só disponivel com sinal de dados disponivel',
              open: true
            });
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          }
        });
      }
    }
  };

  const columns = [
    {
      name: 'codigo',
      label: 'Nota Fiscal',
      options: {
        customBodyRender: (value) => {
          //  console.log(tableMeta);
          return (<b>{value.split('/')[1]}</b>);
        },
        filter: false
      // display: "excluded"
      }
    },
    {
      name: 'codcliente',
      label: 'Cod. Cliente',
      options: {
        filter: false
      // display: "excluded"
      }
    },
    {
      name: 'nomecliente',
      label: 'Cliente',
      options: {
        filter: false
      // display: "excluded"
      }
    },
    {
      name: 'dtvencimento',
      label: 'Vencimento',
      options: {
        customBodyRender: (value) => {
          //  console.log(tableMeta);
          return (<b>{moment(value).format('DD/MM/YYYY')}</b>);
        },
        filter: false
      }
    },
    {
      name: 'valor',
      label: 'Valor',
      options: {
        customBodyRender: (value) => {
          //  console.log(tableMeta);
          return (
            <b>
              { new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2
              }).format(value)}
            </b>
          );
        },
        filter: false
      // display: "excluded"
      }
    }
  ];

  const options = {
    filterType: 'checkbox',
    pagination: false,
    filter: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: 'auto',
    download: false,
    viewColumns: false,
    selectableRows: 'none',
    rowsPerPage: 10,
    responsive: 'simple',
    textLabels: {
      pagination: {
        next: 'Próximo',
        previous: 'Anterior',
        rowsPerPage: 'linhas por Pagina:',
        displayRows: 'de'
      },
      body: {
        noMatch: 'Nenhum registro encontrado',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenado por ${column.label}`
      },
      filter: {
        all: 'Todos',
        title: 'FILTRO',
        reset: 'LIMPAR'
      }
    }

  };

  useEffect(() => {
    setClientes(JSON.parse(localStorage.getItem('MEUSCLIENTES')));
    setTitulos([]);
  }, []);

  return (
    <>
      <BackDrop open={isFetching} />
      <div className={classes.root}>
        <MensagemAlerta
          isOpen={state.open}
          texto={state.texto}
          nivel="warning"
          handleClose={handleCloseMensagem}
        />

        <Card>
          <Box
            display="flex"
            justifyContent="flex-start"
            p={1}
          >
            <Grid
              container
              spacing={3}
            >
              {' '}
              <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <Autocomplete
                  id="cliente"
                  options={clientes}
                  onChange={(event, v) => setCodCliente(v)}
                  value={codcliente}
                  getOptionLabel={(option) => option.fantasia}
                    // style={{ width: 400 }}
                  renderInput={(params) => <TextField {...params} label="Cliente*" variant="outlined" />}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xl={6}
                xs={12}
              >
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={ptLocale}
                >
                  <DatePicker
                    margin="normal"
                    label="Data Inicial"
                    inputVariant="outlined"
                    id="datainicial"
                    format="dd/MM/yyyy"
                    value={datainicial}
                    onChange={handleDataInicial}
                  />
                </MuiPickersUtilsProvider>

              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xl={6}
                xs={12}
              >
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={ptLocale}
                >
                  <DatePicker
                    margin="normal"
                    label="Data Final"
                    inputVariant="outlined"
                    id="datafinal"
                    format="dd/MM/yyyy"
                    minDate={datainicial}
                    value={datafinal}
                    onChange={handleDataFinal}
                  />
                </MuiPickersUtilsProvider>

              </Grid>

            </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            p={1}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={consultarTitulos}
            >
              Consultar
            </Button>
          </Box>

        </Card>

      </div>
      <DialogTelaCheia isOpen={isOpen} handleClose={handleDialogClose} title="Titulos" subtitle="">
        <MUIDataTable
          columns={columns}
          data={titulos}
          options={options}
          components={{
            TableFilterList: CustomFilterList,
          }}
        />
      </DialogTelaCheia>
    </>
  );
};

const Results = () => {
  return (
    <TituloDataTable />
  );
};

export default Results;

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const config = {
  apiKey: "AIzaSyAVeYK560raMP3H42EJ1zEVGhEBZjkR9sA",
  authDomain: "sales-25819.firebaseapp.com",
  projectId: "sales-25819",
  storageBucket: "sales-25819.appspot.com",
  messagingSenderId: "56362322858",
  appId: "1:56362322858:web:f910e7c981309c63e8b9d3",
  measurementId: "G-R6EJMVL5T0"
};

initializeApp(config);
const db = getFirestore();

export default db;

/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import DialogoConfirmacao from './DialogoConfirmacao';

export default function ImgMediaCard(props) {
  // eslint-disable-next-line react/prop-types
  const {
    children, mensagem, handleSalvar, handleAcaoDialogo
  } = props;
  const desabilitabotaoenvio = false;
  const [open, setOpen] = React.useState(false);
  const [openconfirmacao, setisOpenConfirmacao] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleabrirjanela = () => {
    setisOpenConfirmacao(true);
  };

  const handleDialogConfirmacaoClose = () => {
    setisOpenConfirmacao(false);
  };

  return (

    <Card>

      <DialogoConfirmacao
        isOpen={openconfirmacao}
        handleClose={handleDialogConfirmacaoClose}
        handleAcao={() => { handleAcaoDialogo(); setisOpenConfirmacao(false); }}
        disabled={desabilitabotaoenvio}
        title="Confirmação"
        subtitle="Deseja excluir?"
        textoconfirmacao="Ok"
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Informações adicionais</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {mensagem}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => { handleSalvar(); setOpen(false); }} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h2">
            Informações adicionais
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {mensagem}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={handleClickOpen}>
          Editar
        </Button>
        <Button size="small" color="primary" onClick={handleabrirjanela}>
          Excluir
        </Button>
      </CardActions>
    </Card>
  );
}

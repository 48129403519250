import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

const actions = [
  { icon: <LocalShippingIcon />, name: 'Rota Comercial' },
  { icon: <EmojiTransportationIcon />, name: 'Rota Logistica' },
  { icon: <SaveIcon />, name: 'Salvar Pedido' },
];

export default function AcessoRapido(props) {
  const {
    isOpen
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={classes.speedDial}
        hidden={isOpen}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </div>
  );
}

AcessoRapido.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Sales = ({ className, ...rest }) => {
  const classes = useStyles();
  const [metas, setMetas] = useState([]);
  useEffect(() => {
    //  console.log(getIP1());
    setMetas(JSON.parse(localStorage.getItem('META')));
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="Meta por IP"
      />
      <Divider />
      <CardContent>
        <Box
          height={1200}
          position="relative"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  IP
                </TableCell>
                <TableCell>
                  Valor Previsto
                </TableCell>
                <TableCell>
                  Valor Realizado
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>              
                <TableRow>
                  <TableCell>
                    0
                  </TableCell>
                  <TableCell>
                    0
                  </TableCell>
                  <TableCell>
                    0
                  </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </Box>
      </CardContent>
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string
};

export default Sales;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
}));

export default function LayoutDadoItem(props) {
  const {
    handleAcao, desativabotao, produto, valor, qttotal, totalpedido, children
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.section1}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography gutterBottom variant="h4">
              {produto}
            </Typography>
          </Grid>
          <Grid item>
            <Typography gutterBottom variant="h4">
              {'Sugerido: '}
              {valor}
              {' '}
              R$
            </Typography>
          </Grid>
        </Grid>
        {children}
      </div>
      <Divider variant="middle" />
      <div className={classes.section2}>
        <Typography gutterBottom variant="body1">
          Detalhe
        </Typography>
        <div>
          <Chip className={classes.chip} label={`Quantidade: ${qttotal}`} />
          <Chip className={classes.chip} color="primary" label={`Valor Unitário: R$ ${parseFloat(totalpedido / qttotal).toFixed(2)}`} />
          <Chip className={classes.chip} color="primary" label={`Valor Total: R$ ${totalpedido}`} />
        </div>
      </div>
      <div className={classes.section3}>
        <Button color="primary" disabled={desativabotao} onClick={handleAcao}>Adicionar ao Carrinho</Button>
      </div>
    </div>
  );
}

LayoutDadoItem.propTypes = {
  handleAcao: PropTypes.func.isRequired,
  produto: PropTypes.string.isRequired,
  valor: PropTypes.string.isRequired,
  qttotal: PropTypes.string.isRequired,
  totalpedido: PropTypes.string.isRequired,
  desativabotao: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

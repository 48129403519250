/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Button,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  CardContent,
  CardActions,
  Card
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Page from 'src/components/Page';
import Cartao from 'src/components/Card';
import PersonIcon from '@material-ui/icons/Person';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import Swal from 'sweetalert2';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content';
import BackDrop from '../../../components/BackDrop';
import api from '../../../services/api';
import DialogoConfirmacao from '../../../components/DialogoConfirmacao';
import UltimosPedidosVDI from '../../novopedido/NovoPedidoView/UltimosPedidosVDI';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cartao: {
    marginBottom: theme.spacing(3)
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [clientes, setClientes] = useState([]);
  const MySwal = withReactContent(Swal);
  const [isFetching, setisFetching] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [opendialogosalvar, setOpenDialogSalvar] = React.useState(false);
  const [isOpenConfirmacao, setisOpenConfirmacao] = React.useState(false);
  const [desabilitabotaoenvio, setdesabilitabotaoenvio] = React.useState(false);
  const [informacaoadicional, setinformacaoadicional] = React.useState([]);
  const [descricao, setDescricao] = React.useState('');
  const [codigocliente, setCodigoCliente] = React.useState(null);
  const [nomecliente, setNomeCliente] = React.useState('');

  const [contato, setContato] = React.useState('');
  const [telcontato, setTelContato] = React.useState('');
  const [emailcontato, setEmailContato] = React.useState('');

  const [pedidospendentes, setPedidosPendentes] = useState([]);

  const getInformacaoAdicional = (cod) => {
    if (cod) {
      setisFetching(true);
      api.get(
        `/pedidos-sales/v2/pedido/informacoesadicionais/${cod}`
      ).then((response) => {
        console.log(response);
        setinformacaoadicional(response.data);
      }).catch((error2) => {

      });
      setisFetching(false);
    }
  };

  const handleExclusao = async () => {
    if (codigocliente) {
      setisFetching(true);
      api.post(
        '/pedidos-sales/v2/pedido/apagarinformacoesadicionais',
        {
          codigo: codigocliente
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      ).then((response) => {
        console.log(response);
        getInformacaoAdicional(codigocliente);
      }).catch((error2) => {

      });
      setisFetching(false);
      MySwal.fire({
        icon: 'success',
        title: 'Alerta',
        text: 'Nota excluida'
      });
    }
  };

  const handleSalvar = async () => {
    if (codigocliente) {
      setisFetching(true);
      api.post(
        '/pedidos-sales/v2/pedido/apagarinformacoesadicionais',
        {
          codigo: codigocliente
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      ).then((response) => {
        console.log(response);
        api.post(
          '/pedidos-sales/v2/pedido/informacoesadicionais',
          {
            codigo: codigocliente,
            descricao
          },
          {
            headers: {
              'Content-Type': 'application/json',
            }
          }
        ).then((response2) => {
          MySwal.fire({
            icon: 'success',
            title: 'Alerta',
            text: 'Nota alterada'
          });
          getInformacaoAdicional(codigocliente);
        }).catch((error2) => {
          console.log(error2);
        });
      }).catch((error) => {
        console.log(error);
      });
      setisFetching(false);
    } else {
      MySwal.fire({
        icon: 'error',
        title: 'Alerta',
        text: 'Favor escolher o cliente'
      });
    }
  };

  const handleCloseDialogSalvar = () => {
    setOpenDialogSalvar(false);
  };

  const handleAtualizarclientes = () => {
    setisFetching(true);
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    api.post(
      '/api-pedidos/vendedoremail',
      {
        email: vendedorlogado.email
      },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    ).then((response) => {
      localStorage.setItem('MEUSCLIENTES', JSON.stringify(response.data.data[0].clientes));
      setClientes(response.data.data[0].clientes);
    }).catch((error) => {
      console.log(error);
    });
    setisFetching(false);
    MySwal.fire({
      icon: 'success',
      title: 'Alerta',
      text: 'Clientes atualizados!'
    });
  };

  useEffect(() => {
    setClientes(JSON.parse(localStorage.getItem('MEUSCLIENTES')));
  }, []);

  const columns = [
    {
      name: 'codigo',
      label: 'Codigo',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'fantasia',
      label: 'Nome',
      options: {
        filter: false
      }
    },
    {
      name: 'despagamento',
      label: 'Forma Pagamento',
      options: {
        filter: false,
        display: false
      }
    },
    {
      name: 'codtabela',
      label: 'Tabela',
      options: {
        filter: true
      }
    },
    {
      name: 'limitecredito',
      label: 'Limite Credito',
      options: {
        customBodyRender: (value) => {
        // console.log(tableMeta);
          return (
            <b>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2
              }).format(value.replace(',', '.'))}
            </b>
          );
        },
        filter: false
      // display: "excluded"
      }
    },
    {
      name: 'dataultimacompra',
      label: 'Data Ultima Compra',
      options: {
        customBodyRender: (value) => {
        // console.log(tableMeta);
          return (<b>{moment(value).format('DD/MM/YYYY')}</b>);
        },
        filter: false
      }
    },
    {
      name: 'rotaentrega',
      label: 'Rota Entrega',
      options: {
        filter: false,
        display: false
      // display: "excluded"
      },
    },
    {
      name: 'cnpj',
      label: 'CNPJ',
      options: {
        filter: false,
        display: false
      // display: "excluded"
      },
    },
    {
      name: 'endereco',
      label: 'Endereço',
      options: {
        filter: false,
        display: false
      },
    },
    {
      name: 'bairro',
      label: 'Bairro',
      options: {
        filter: false,
        display: false
      },
    },
    {
      name: 'cidade',
      label: 'Cidade',
      options: {
        filter: false,
        display: false
      },
    },
    {
      name: 'estado',
      label: 'Estado',
      options: {
        filter: false,
        display: false
      },
    },
    {
      name: 'telcontato',
      label: 'telcontato',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'contato',
      label: 'contato',
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: 'emailcontato',
      label: 'emailcontato',
      options: {
        filter: false,
        display: 'excluded'
      }
    }];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // background: '#fff !important',
      // match with the menu
      // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? 'yellow' : 'green',
      // Removes weird border around container
      width: '400px',
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? 'red' : 'blue'
      }
    }),

  };

  const changeRowColor = (index) => {
    console.log(index);
    const row = document.getElementById(`MUIDataTableBodyRow-${index}`);
    row.setAttribute('style', 'background: yellow');
  };

  const GetUltimosPedidos = async (codigo) => {
    let encontrou = false;
    setisFetching(true);
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    const response = await api.post(
      '/api-pedidos/ultimospedidoscliente',
      {
        codvendedor: vendedorlogado.codigo,
        codcliente: codigo
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    console.log(response.data.data);
    if (response.data.data) {
      console.log('dados');
      console.log(response.data.data);
      encontrou = true;
      setPedidosPendentes(response.data.data);
    } else {
      encontrou = false;
      setPedidosPendentes([]);
    }

    if (encontrou === false) {
      const response2 = await api.post(
        '/api-pedidos/ultimospedidosvendedorinternocliente',
        {
          email: vendedorlogado.email,
          codcliente: codigo
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response2.data.data) {
        encontrou = true;
        console.log('dados');
        console.log(response.data.data);
        setPedidosPendentes(response2.data.data);
      } else {
        encontrou = false;
        setPedidosPendentes([]);
      }
    }
    setisFetching(false);
  };

  const options = {
    filterType: 'checkbox',
    pagination: true,
    filter: true,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: 'auto',
    download: true,
    viewColumns: true,
    selectableRows: 'none',
    rowsPerPage: 5,
    responsive: 'simple',
    onRowClick: (rowData, rowIndex) => {
      // changeRowColor(rowIndex.rowIndex);
      setCodigoCliente(parseInt(rowData[0], 10));
      getInformacaoAdicional(rowData[0]);
      console.log(rowData[0]);
      GetUltimosPedidos(rowData[0]);
      setNomeCliente(rowData[1]);
      setContato(rowData[13]);
      setTelContato(rowData[12]);
      setEmailContato(rowData[14]);
      console.log(rowData, rowIndex);
    },
    textLabels: {
      pagination: {
        next: 'Próximo',
        previous: 'Anterior',
        rowsPerPage: 'linhas por Pagina:',
        displayRows: 'de'
      },
      body: {
        noMatch: 'Nenhum registro encontrado',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenado por ${column.label}`
      },
      filter: {
        all: 'Todos',
        title: 'FILTRO',
        reset: 'LIMPAR'
      }
    }

  };

  const diasentrega = [
    { texto: 'Segunda', dia: '2' },
    { texto: 'Terça', dia: '3' },
    { texto: 'Quarta', dia: '4' },
    { texto: 'Quinta', dia: '5' },
    { texto: 'Sexta', dia: '6' },
  ];

  function hasThanMore(cliente, dia) {
    return cliente.rotacliente.indexOf(dia) !== -1;
  }

  const handleClickOpen = () => {
    setOpenDialogSalvar(true);
  };

  const handleDiaEntrega = (value) => {
    if (value.length === 0) {
      setClientes(JSON.parse(localStorage.getItem('MEUSCLIENTES')));
    } else {
      let resultado = [];
      const clientesl = JSON.parse(localStorage.getItem('MEUSCLIENTES'));
      value.forEach((p) => {
        // console.log(p);
        // resultado = clientesl.filter((cliente) => hasThanMore(cliente, p.dia));
        resultado = resultado.concat(clientesl.filter((cliente) => hasThanMore(cliente, p.dia)));
      });
      setClientes(resultado);
    }
  };

  const handleDialogConfirmacaoClose = () => {
    setisOpenConfirmacao(false);
  };

  const handleAcaoDialogo = async () => {
    setdesabilitabotaoenvio(false);
    console.log('ok');
    handleDialogConfirmacaoClose(true);
  };

  return (
    <div>
      <Page
        className={classes.root}
        title="Dashboard"
      >
        <Dialog open={opendialogosalvar} onClose={handleCloseDialogSalvar} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Informações adicionais</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Descrição"
              type="text"
              multiline
              rows={4}
              defaultValue={informacaoadicional.descricao}
              onChange={(e) => setDescricao(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogSalvar} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => { handleSalvar(); setOpen(false); }} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <DialogoConfirmacao
          isOpen={isOpenConfirmacao}
          handleClose={handleDialogConfirmacaoClose}
          handleAcao={handleAcaoDialogo}
          disabled={desabilitabotaoenvio}
          title="Confirmação"
          subtitle="Deseja excluir?"
          textoconfirmacao="Ok"
        />
        <BackDrop open={isFetching} />

        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAtualizarclientes}
                startIcon={<CloudDownloadIcon />}
              >
                Atualizar Clientes
              </Button>

            </Grid>

            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <Alert icon={<ContactMailIcon fontSize="inherit" />} severity="warning" variant="filled">
                <AlertTitle>Clientes</AlertTitle>
                <strong>{clientes.length}</strong>
              </Alert>
            </Grid>

            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <Typography variant="body1" gutterBottom>Dia de Contato</Typography>
              <Select
                isMulti
                styles={customStyles}
                getOptionLabel={(option) => option.texto}
                getOptionValue={(option) => option.dia}
                onChange={(v) => handleDiaEntrega(v)}
                options={diasentrega}
                label="Dia de Entrega"
              />

            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <Alert icon={<MenuBookIcon fontSize="inherit" />} severity="info" variant="filled">
                <strong>Agenda detalhada do dia</strong>
              </Alert>
            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <Divider />
            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <Alert icon={<PersonIcon fontSize="inherit" />} variant="outlined" severity="info">
                <AlertTitle>Cliente</AlertTitle>
                <strong>{nomecliente}</strong>
              </Alert>
            </Grid>
            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={6}
            >
              <MUIDataTable
                columns={columns}
                data={clientes}
                options={options}
              />

            </Grid>
            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={6}
              spacing={3}
            >
              <Card className={classes.cartao}>
                <CardContent>
                  <Typography variant="h4" color="textSecondary" gutterBottom>
                    Contato
                  </Typography>
                  <Typography variant="h6" className={classes.pos} component="p">
                    Contato:
                    {' '}
                    {contato}
                  </Typography>
                  <Typography variant="h6" className={classes.pos} component="p">
                    Telefone:
                    {' '}
                    {telcontato}
                  </Typography>
                  <Typography variant="h6" className={classes.pos} component="p">
                    Email:
                    {' '}
                    {emailcontato}
                  </Typography>
                </CardContent>
              </Card>
              <Cartao mensagem={informacaoadicional.descricao} handleSalvar={handleSalvar} handleAcaoDialogo={handleExclusao}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Descrição"
                  type="text"
                  multiline
                  rows={4}
                  defaultValue={informacaoadicional.descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                  fullWidth
                />
              </Cartao>

            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <UltimosPedidosVDI
                pedidos={pedidospendentes}
              />

            </Grid>
          </Grid>
        </Container>
      </Page>
    </div>
  );
};

export default Dashboard;

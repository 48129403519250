/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import MuiAlert from '@material-ui/lab/Alert';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import StoreIcon from '@material-ui/icons/Store';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import NumberFormat from 'react-number-format';
import AppBar from '@material-ui/core/AppBar';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import MenuTemporario from './MenuTemporario';
import LayoutDadoItem from './LayoutDadoItem';
import DialogoConfirmacao from './DialogoConfirmacao';
import Papel from './Papel';
import api from '../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  appBar: {
    position: 'relative'
  },
  papel: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(60),
      height: theme.spacing(16)
    }
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ListaItemPedido() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [maco, setMaco] = useState(null);
  const [porcentagemmaco, setPorcentagemMaco] = useState(0);
  const [totalmaco, setTotalMaco] = useState(0);
  const [estoque, setEstoque] = useState([]);
  const [estoqueatual, setestoqueatual] = useState(null);
  const [produtoselecionado, setprodutoselecionado] = useState(null);
  // const [clientepedidoescolhidovalorminimo, setclientepedido] = useState('');
  const [qt, setQt] = useState(0);
  const [totalproduto, setTotalProduto] = useState(0);
  const [totalpedido, setTotalPedido] = useState(0);
  const [totalpesobruto, setTotalPesoBruto] = useState(0);
  // const [totalpedido, setTotalPedido] = useState(0);
  const [descontoitem, setDescontoitem] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [isOpenConfirmacao, setisOpenConfirmacao] = useState(false);
  const [usafretenopedido, setUsaFreteNoPedido] = useState(false);
  const [state, setState] = useState({
    open: false,
    Transition: Fade,
    Item: ''
  });
  const [abaixodominimo, setabaixodominimo] = useState(false);
  const [precoacimadomaximo, setprecoacimadomaximo] = useState(false);

  const calculaotalpedido = () => {
    let ctotalproduto = 0;
    const datacarrinho = JSON.parse(localStorage.getItem('PRODUTOSCARRINHO'));

    const cpesototal = datacarrinho.reduce((resultado, produto) => {
      return resultado + Number(produto.peso) * Number(produto.quantidade);
    }, 0);

    setTotalPesoBruto(parseFloat(cpesototal));

    datacarrinho.forEach((produto) => {
      ctotalproduto = Number(produto.totalproduto) + ctotalproduto;
      const total_pedido_convertido = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      }).format(parseFloat(ctotalproduto).toFixed(2));
      setTotalPedido(total_pedido_convertido);
    });
    if (datacarrinho.length === 0) {
      setTotalPedido(0);
    }
  };

  const obtenprodutoscarrinho = () => {
    setData(JSON.parse(localStorage.getItem('PRODUTOSCARRINHO')));
  };

  const roundNumber = (x) => Number.parseFloat(x).toFixed(2);

  useEffect(() => {
    async function getMaco() {
      const cliente_pedido = JSON.parse(localStorage.getItem('CLIENTEPEDIDO'));
      const produto_carrinho = JSON.parse(
        localStorage.getItem('PRODUTOSCARRINHO')
      );
      const items = [];

      produto_carrinho.forEach((produto) =>
        items.push({
          sku: produto.codigo,
          qt: Number(produto.quantidade),
          peso: Number(produto.peso),
          valor: Number(roundNumber(Number(produto.totalproduto) / Number(produto.quantidade))),
          descontocd1510: 50
        })
      );

      const response = await api.post(
        '/api-pedidos/maco',
        {
          unidade: cliente_pedido.regiao,
          matriz_com: cliente_pedido.rede,
          cod_cliente: Number(cliente_pedido.codigo),
          items
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('RESULTADO RESPONSE');
      console.log(response);
      setMaco(response.data);
      setPorcentagemMaco(response.data.porcentagemmacopedido);
      const total_maco = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      }).format(response.data.totalmacoitens);
      setTotalMaco(total_maco);
    }
    getMaco();
    obtenprodutoscarrinho();
    setEstoque(JSON.parse(localStorage.getItem('ESTOQUEPRODUTO')));
    const frete_no_pedido = localStorage.getItem('USAFRETENOPEDIDO');
    if (frete_no_pedido === 'true') {
      setUsaFreteNoPedido(true);
    } else {
      setUsaFreteNoPedido(false);
    }

    // setclientepedido(JSON.parse(localStorage.getItem('CLIENTEPEDIDO')).valorminimo);
    calculaotalpedido();
    setabaixodominimo(false);
    setprecoacimadomaximo(false);
  }, []);

  const handleTotalItemPedido = (e) => {
    let dtotalproduto = 0;
    if (descontoitem === 0) {
      dtotalproduto = Number(produtoselecionado.precomaximo) * e.target.value;
      setTotalProduto(parseFloat(dtotalproduto).toFixed(2));
    } else {
      // eslint-disable-next-line max-len
      dtotalproduto =
        (produtoselecionado.precomaximo -
          produtoselecionado.precomaximo * (descontoitem / 100)) *
        e.target.value;
      setTotalProduto(parseFloat(dtotalproduto).toFixed(2));
    }
  };

  const handleTotalItemPedidoDesconto = (e) => {
    setabaixodominimo(false);
    if (Number(e.target.value) <= Number(produtoselecionado.precomaximo)) {
      setprecoacimadomaximo(false);
      let dtotalproduto = 0;
      if (e.target.value === 0) {
        dtotalproduto = Number(produtoselecionado.precomaximo) * qt;
        setTotalProduto(parseFloat(dtotalproduto).toFixed(2));
      } else {
        // eslint-disable-next-line max-len
        // MODELO ANTIGO BASEDO NO DESCONTO DO VALOR
        // dtotalproduto = (produtoselecionado.precomaximo - e.target.value) * qt;

        dtotalproduto = Number(e.target.value) * qt;
        setTotalProduto(parseFloat(dtotalproduto).toFixed(2));
      }

      // console.log((e.target.value / produtoselecionado.precomaximo) * 100);

      /* MODELO ANTIGO DE APLICACAO DE VALIDACAO DE PRECO MINIMO.
    if (parseFloat(dtotalproduto / qt).toFixed(2) < Number(String(produtoselecionado.precominimo).replace(',', '.'))) {
      setabaixodominimo(true);
    } else {
      setabaixodominimo(false);
    } */

      if (
        Number(e.target.value) <
        Number(String(produtoselecionado.precominimo).replace(',', '.'))
      ) {
        setabaixodominimo(true);
      } else {
        setabaixodominimo(false);
      }
    } else {
      setprecoacimadomaximo(true);
    }
  };

  const handleExcluirItem = () => {
    const arr = data.filter(
      (item) => item.codigo !== produtoselecionado.codigo
    );
    setData(arr);
    localStorage.setItem(
      'PRODUTOSCARRINHO',
      JSON.stringify(arr)
    );
    setisOpenConfirmacao(false);
    calculaotalpedido();
  };

  const handleDialogConfirmacaoClose = () => {
    setisOpenConfirmacao(false);
  };

  const handleDelete = (produto) => {
    setprodutoselecionado(produto); 
    console.log('este é o ponto');
    setisOpenConfirmacao(true);
  };

  const handleEditar = (produto) => {
    const clientepedido = JSON.parse(localStorage.getItem('CLIENTEPEDIDO'));
    const estoqueatualproduto = estoque.filter((p) => {
      // eslint-disable-next-line max-len
      return (
        p.codestabelecimento === clientepedido.codestabelecimento &&
        p.codigo === produto.codigo
      );
    });
    setestoqueatual(estoqueatualproduto);
    setprodutoselecionado(produto);
    setDescontoitem(0);
    setQt(0);
    setTotalProduto(0);
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const handleAcao = () => {
    if (abaixodominimo) {
      setState({
        ...state,
        open: true,
        Item: 'Produto abaixo do Valor Minimo',
        tipoalerta: 'error'
      });
    } else {
      if (descontoitem === 0) {
        produtoselecionado.totalproduto = produtoselecionado.precomaximo * qt;
        produtoselecionado.desconto = '0';
      } else {
        produtoselecionado.desconto = String(
          ((1 - descontoitem / produtoselecionado.precomaximo) * 100).toFixed(2)
        );
      }

      const produtoscarrinho = JSON.parse(
        localStorage.getItem('PRODUTOSCARRINHO')
      );
      produtoscarrinho.find(
        (someobject) => someobject.codigo === produtoselecionado.codigo
      ).quantidade = qt;
      if (descontoitem === 0) {
        produtoscarrinho.find(
          (someobject) => someobject.codigo === produtoselecionado.codigo
        ).totalproduto = produtoselecionado.totalproduto * qt;
        produtoscarrinho.find(
          (someobject) => someobject.codigo === produtoselecionado.codigo
        ).desconto = '0';
      } else {
        produtoscarrinho.find(
          (someobject) => someobject.codigo === produtoselecionado.codigo
        ).totalproduto = descontoitem * qt;
        produtoscarrinho.find(
          (someobject) => someobject.codigo === produtoselecionado.codigo
        ).desconto = String(
          ((1 - descontoitem / produtoselecionado.precomaximo) * 100).toFixed(2)
        );
      }

      setData(produtoscarrinho);
      localStorage.setItem(
        'PRODUTOSCARRINHO',
        JSON.stringify(produtoscarrinho)
      );
      handleDialogClose();
      calculaotalpedido();
      setState({
        ...state,
        open: true,
        Item: `${produtoselecionado.descricao} alterado`
      });
    }
  };

  const handleCloseMenuRapido = () => {
    setisOpen(false);
  };

  return (
    <div className={classes.root}>
      <DialogoConfirmacao
        isOpen={isOpenConfirmacao}
        handleClose={handleDialogConfirmacaoClose}
        handleAcao={handleExcluirItem}
        title="Confirma"
        subtitle="Confirma Exclusão"
        textoconfirmacao="Retirar do Carrinho"
      />
      <MenuTemporario isOpen={isOpen} handleClose={handleDialogClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseMenuRapido}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {produtoselecionado && (
          <LayoutDadoItem
            handleAcao={handleAcao}
            desativabotao={precoacimadomaximo}
            produto={produtoselecionado.descricao}
            qttotal={qt}
            totaldesconto={descontoitem}
            totalpedido={totalproduto}
            valor={
              <>
                <NumberFormat
                  value={Number(
                    String(produtoselecionado.precomaximo).replace(',', '.')
                  )}
                  displayType="text"
                  thousandSeparator
                  prefix=""
                />
              </>
            }
          >
            {abaixodominimo && (
              <Alert severity="warning">Abaixo do Minimo</Alert>
            )}
            <List className={classes.lista}>
              <ListItem button key={produtoselecionado.precominimo}>
                <ListItemIcon>
                  <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      <NumberFormat
                        value={Number(
                          String(produtoselecionado.precominimo).replace(
                            ',',
                            '.'
                          )
                        )}
                        displayType="text"
                        thousandSeparator
                        prefix="Preço Minimo R$: "
                      />
                    </>
                  }
                />
              </ListItem>
              {estoqueatual && (
                <ListItem button key="estoque">
                  <ListItemIcon>
                    <StoreIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Estoque: ${estoqueatual[0].quantidade} CX`}
                  />
                </ListItem>
              )}
            </List>
            <TextField
              margin="dense"
              id="quantidade"
              label="Quantidade"
              type="number"
              variant="outlined"
              value={qt}
              onChange={(e) => {
                setQt(e.target.value);
                handleTotalItemPedido(e);
              }}
              // onBlur={handleTotalItemPedido}
              fullWidth
            />
            <TextField
              margin="dense"
              id="desconto"
              label="Valor de Venda"
              type="number"
              variant="outlined"
              value={descontoitem}
              onChange={(e) => {
                setDescontoitem(e.target.value);
                handleTotalItemPedidoDesconto(e);
              }}
              fullWidth
            />
          </LayoutDadoItem>
        )}
      </MenuTemporario>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Alert variant="outlined" severity="info">
            Quantidade de Produtos no Carrinho : {data.length}
          </Alert>
        </Grid>
        <Grid item xs={12} md={12}>
          <Alert variant="outlined" severity="info">
            Total : {totalpedido}
          </Alert>
        </Grid>
        <Grid item xs={12} md={12}>
          <Alert variant="outlined" severity="info">
            Peso : {Number.parseFloat(totalpesobruto).toFixed(2)} KG
          </Alert>
        </Grid>
        <Grid item xs={12} md={12}>
          {maco && (
            <Alert variant="outlined" severity="info">
              MACO Valor: {totalmaco}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          {porcentagemmaco < 30 && maco && (
            <Alert variant="outlined" severity="error">
              {' '}
              MACO: {porcentagemmaco} %
            </Alert>
          )}

          {porcentagemmaco > 30 && porcentagemmaco < 44 && maco && (
            <Alert variant="outlined" severity="warning">
              {' '}
              MACO: {porcentagemmaco} %
            </Alert>
          )}

          {maco && porcentagemmaco >= 45 && (
            <Alert variant="outlined" severity="success">
              {' '}
              MACO: {porcentagemmaco} %
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <div className={classes.demo}>
            <List>
              {data.map((produto) => (
                <ListItem key={produto.codigo}>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={produto.descricao}
                    secondary={`Quantidade: ${
                      produto.quantidade
                    } CX - Desconto: ${
                      produto.desconto
                    } % - Valor Unitário: R$ ${parseFloat(
                      produto.totalproduto / produto.quantidade
                    ).toFixed(2)}  `}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="editar"
                      onClick={() => handleEditar(produto)}
                    >
                      <CreateIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDelete(produto)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>

        <Grid item lg={8} md={12} xl={9} xs={12}>
          {usafretenopedido && (
            <>
              <Alert severity="info">
                Sr (a), consultor (a), será cobrado uma taxa de conveniência de
                R$ 49,90, por se tratar de um pedido Fora de Rota. Confirma o
                envio com a cobrança?
              </Alert>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import moment from 'moment';
import Budget from './Budget';
import Sales from './Sales';
import TasksProgress from './TasksProgress';
import TotalProfit from './TotalProfit';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const getConsultarPedidos = async () => {
  const today = moment().format('YYYY-MM-DD');
  const mespassado = moment().add(-3, 'month').format('YYYY-MM-DD');
  //  console.log(today, mespassado);
  const usuariologado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
  const response = await api.post(
    '/api-pedidos/vendedordata',
    {
      codrep: usuariologado.codigo,
      dtinicio: mespassado,
      dtfim: today
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  /*
  const dadosagrupados = response.data.reduce((r, e) => {
    r[e.desstpedido] = (r[e.desstpedido] || 0) + 1;
    return r;
  }, {}); */
  /*
  console.log('meus pedidos');
  console.log(response.data);
  console.log('dados agrupados');
  console.log(dadosagrupados); */
  localStorage.setItem('ULTIMOSPEDIDOS', JSON.stringify(response.data));
};

const getEstoqueProduto = async () => {
  const response = await api.get(
    '/api-pedidos/estoqueall',
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  localStorage.setItem(
    'ESTOQUEPRODUTO',
    JSON.stringify(response.data.data)
  );
};

const getMotivoAprovacao = async () => {
  const response = await api.get(
    '/api-pedidos/argumentosaprovacao',
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  localStorage.setItem(
    'MOTIVOAPROVACAO',
    JSON.stringify(response.data)
  );
};

const Dashboard = () => {
  const classes = useStyles();
  useEffect(() => {
    const produtospendenteenvio = [];
    localStorage.setItem('PEDIDOSPENDENTESENVIO', JSON.stringify(produtospendenteenvio));
    const usuariologado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    // VENDEDOR
    if (usuariologado.perfil === '3') getEstoqueProduto();
    if (usuariologado.perfil === '3') getMotivoAprovacao();
    // SUPERVISOR
    if (usuariologado.perfil === '1') getConsultarPedidos();
    // VDI
    if (usuariologado.perfil === '2') getConsultarPedidos();
    if (usuariologado.perfil === '2') getMotivoAprovacao();
    // getVendedorLogado();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >

      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >

          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >

            <Budget />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <TotalProfit />
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <TasksProgress />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Sales />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;

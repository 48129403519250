/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';

export default function MenuTemporario(props) {
  const {
    isOpen, handleClose, children
  } = props;

  return (
    <div>
      <React.Fragment key="MenuTemporario">
        <Drawer anchor="bottom" open={isOpen} onClose={handleClose} disableRestoreFocus={true} disableEnforceFocus={true} disableAutoFocus={true}>
          {children}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

MenuTemporario.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
// import PouchDB from 'pouchdb';
import { useNavigate } from 'react-router-dom';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DialogTelaCheia from './DialogTelaCheia';
import ListaItemPedido from './ListaItemPedido';
import DialogoConfirmacao from './DialogoConfirmacao';
import api from '../services/api';
import BackDrop from './BackDrop';
import db from '../firebase/firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    minHeight: '100%'
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

const PrimarySearchAppBar = (props) => {
  const { onInput, qtitems } = props;
  const classes = useStyles();
  const [isOpen, setisOpen] = React.useState(false);
  const [isOpenConfirmacao, setisOpenConfirmacao] = React.useState(false);
  const [desabilitabotaoenvio, setdesabilitabotaoenvio] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const MySwal = withReactContent(Swal);
  const [isFetching, setisFetching] = React.useState(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const navigate = useNavigate();

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleProfileMenuOpen = () => {
    const produtoscarrinho = JSON.parse(
      localStorage.getItem('PRODUTOSCARRINHO')
    );
    console.log(produtoscarrinho.length);
    if (produtoscarrinho.length > 0) {
      handleDialogOpen();
    } else {
      MySwal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Favor inserir itens no carrinho!'
      });
    }
  };

  const handleMobileMenuOpen = () => {
    const produtoscarrinho = JSON.parse(
      localStorage.getItem('PRODUTOSCARRINHO')
    );
    if (produtoscarrinho.length > 0) {
      handleDialogOpen();
    } else {
      MySwal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Favor inserir itens no carrinho!'
      });
    }
  };

  const handleEnviarPedido = () => {
    setisOpenConfirmacao(true);
  };

  function getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const onlineCheck = async () => {
    const response = await api.get(
      'https://httpbin.org/get',
      {},
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response.status;
  };

  const FuncEnviarPedido = async () => {
    const pedidospendenteenviolista = [];
    let enviadocomsucesso = true;
    setisFetching(true);
    const csrftoken = getCookie('csrftoken');
    // CONSTRUINDO O DADO QUE SERÁ ENVIADO PARA A PARTE ADMINISTRATIVA DO NOVO PEDIDO.
    const pedido = [];
    const itempedido = [];
    const rota = [];
    const motivoaprovacao = JSON.parse(
      localStorage.getItem('ARGUMENTOSAPROVACAO')
    );
    let stipopedido = '';
    let campanhaliberadas = JSON.parse(
      localStorage.getItem('CAMPANHASLIBERADAS')
    );
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    const clientepedido = JSON.parse(localStorage.getItem('CLIENTEPEDIDO'));
    const produtoscarrinho = JSON.parse(
      localStorage.getItem('PRODUTOSCARRINHO')
    );
    const tipopedido = JSON.parse(localStorage.getItem('TIPOPEDIDO'));
    const dataentrega = localStorage.getItem('DATAENTREGA');
    const frete_no_pedido = localStorage.getItem('USAFRETENOPEDIDO');
    const numpedidrepresentante = localStorage.getItem('PEDIDOREPRES');
    const observacaopedido = localStorage.getItem('OBSERVACAOPEDIDO');
    let fretenopedido = false;

    if (tipopedido.tipopedido === 1) {
      stipopedido = 'Venda';
    } else {
      stipopedido = 'Bonificação';
    }

    if (frete_no_pedido === 'true') {
      fretenopedido = true;
    } else {
      fretenopedido = false;
    }

    if (campanhaliberadas) {
      campanhaliberadas = campanhaliberadas.filter((e) => {
        return e.utiliza === true;
      });
    }

    rota.push({
      dias: clientepedido.rotaentrega
    });
    pedido.push({
      num_pedido: numpedidrepresentante,
      cod_cliente: clientepedido.codigo,
      cliente: clientepedido.nome,
      cod_vendedor: vendedorlogado.codigo,
      vendedor: vendedorlogado.nome,
      emailsupervisor: vendedorlogado.emailsupervisor,
      emailgerente: vendedorlogado.emailgerente,
      tipo: stipopedido,
      dataentrega,
      fretenopedido, // ALTERADO (CASO O VENDEDOR SELECIONE A OPCAO, NAO IRA MAIS PARA APROVACAO DO GERENTE SEGUIRA O FLUXO NORMAL)
      valor_despesa_frete: 0
    });

    produtoscarrinho.forEach((valor) => {
      itempedido.push({
        cd_pedido_palm: 'FDM48J', // GERAR AUTOMATICAMENTE NO PYTHON
        cd_cliente: clientepedido.codigo,
        dt_emissao: moment(new Date()).format('DD/MM/YYYY'),
        vr_pedido: valor.totalproduto,
        cd_org_venda: clientepedido.codestabelecimento,
        cd_pedido_cliente: 'FDM48J', // GERAR AUTOMATICAMENTE NO PYTHON
        cd_pedido: 'FDM48J', // GERAR AUTOMATICAMENTE NO PYTHON
        cd_cond_pgto: clientepedido.codpagamento,
        cd_tipo_pedido: String(tipopedido.tipopedido),
        dt_entrega: dataentrega,
        cd_meio_pgto: clientepedido.codpagamento,
        cd_st_pedido: '',
        id_prioridade: '',
        cd_vendedor: vendedorlogado.codigo,
        id_tipo_frete: 'CIF',
        cd_transportadora: '',
        ds_observacao: observacaopedido,
        nm_rz_social_ent: clientepedido.nome,
        ds_endereco_entr: '',
        nm_bairro_entr: '',
        nm_cidade_entr: '',
        nm_estado_entr: clientepedido.estado,
        nr_cep_entr: '',
        nr_fone_entr: '',
        nr_cnpj_cpf_entr: clientepedido.cnpj,
        nr_cgf_entr: '',
        cd_pedido_palm_pai: '',
        cd_tab_preco: clientepedido.codtabela,
        id_orcamento: '',
        cd_motivo: '',
        cd_produto: valor.codigo,
        produto: valor.descricao,
        qt_item: valor.quantidade,
        vr_item: valor.precomaximo,
        vr_item_original: valor.totalproduto,
        vr_item_minimo: valor.precominimo,
        peso_liquido: valor.peso,
        pc_desconto: valor.desconto,
        cod_familia: valor.codfamilia,
        familiacomercial: valor.subfamilia,
        familia: valor.desfamilia,
        subfamilia: valor.subfamilia,
        canal_venda: clientepedido.canalvenda,
        dias: clientepedido.rotaentrega,
        num_pedido: numpedidrepresentante,
        cod_cliente: clientepedido.codigo,
        cliente: clientepedido.nome,
        cod_vendedor: vendedorlogado.codigo,
        vendedor: vendedorlogado.nome,
        emailsupervisor: vendedorlogado.emailsupervisor,
        emailgerente: vendedorlogado.emailgerente,
        tipo: stipopedido,
        dataentrega,
        fretenopedido, // ALTERADO (CASO O VENDEDOR SELECIONE A OPCAO, NAO IRA MAIS PARA APROVACAO DO GERENTE SEGUIRA O FLUXO NORMAL)
        valor_despesa_frete: 0,
        motivo_aprovacao_bonificacao: motivoaprovacao.bonificacao,
        motivo_aprovacao_forarota: motivoaprovacao.forarota
      });
    });

    /*
    const docSnap = await getDocFromCache(usuarioRef);
    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
    } */

    console.log("itemPedido");
    console.log(itempedido);

    api
      .post(
        '/sales-pedido-venda/Pedido',        
          itempedido,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        setisFetching(false);
        // AQUI VAI FICAR O ENVIO DO PEDIDO
        setisOpenConfirmacao(false);
        enviadocomsucesso = true;
        MySwal.fire({
          icon: 'success',
          title: 'Pedido',
          text: response.data.message
        });
        localStorage.setItem('CLIENTEPEDIDO', null);
        navigate('/app/dashboard');
      })
      .catch((error) => {
        enviadocomsucesso = false;
        if (error.response) {
          enviadocomsucesso = false;
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          const clientepedidosalvo = JSON.parse(
            localStorage.getItem('CLIENTEPEDIDO')
          );
          const itenspedidosalvo = JSON.parse(
            localStorage.getItem('PRODUTOSCARRINHO')
          );
          console.log(error.response.status);
          if (error.response.status === 401) {
            console.log(error.response.status);
          }

          if (error.response.status === 400) {
            const registrofirebase = null;
            const pedidogravado3 = {
              cliente: clientepedidosalvo,
              pedido: itenspedidosalvo,
              vendedor: vendedorlogado,
              tipopedido: stipopedido,
              dataentrega,
              numpedidrepresentante,
              observacaopedido
            };
            pedidospendenteenviolista.push(pedidogravado3);
            setisFetching(false);
            // AQUI VAI FICAR O ENVIO DO PEDIDO
            setisOpenConfirmacao(false);
            MySwal.fire({
              icon: 'success',
              title: 'Pedido',
              text: 'Pedido enviado com sucesso'
            });
            localStorage.setItem('CLIENTEPEDIDO', null);
            navigate('/app/dashboard');
          }

          if (error.response.status === 404) {
            const registrofirebase = null;

            const pedidogravado3 = {
              cliente: clientepedidosalvo,
              pedido: itenspedidosalvo,
              vendedor: vendedorlogado,
              tipopedido: stipopedido,
              dataentrega,
              numpedidrepresentante,
              observacaopedido
            };
            pedidospendenteenviolista.push(pedidogravado3);
            MySwal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'O pedido foi salvo. Você deverá sincronizar no botão relatorio de pedidos'
            });
            setisFetching(false);
            // AQUI VAI FICAR O ENVIO DO PEDIDO
            setisOpenConfirmacao(false);
            MySwal.fire({
              icon: 'success',
              title: 'Pedido',
              text: 'Pedido enviado com sucesso'
            });
            localStorage.setItem('CLIENTEPEDIDO', null);
            navigate('/app/dashboard');
          }
          // console.log(error.response.headers);
        } else if (error.request) {
          const registrofirebase = null;
          /* if (docSnap.exists()) {
          registrofirebase = docSnap.data();
          console.log('Document data vou gravar o pedido:', docSnap.data());
        }
        if (registrofirebase.pedidospendentesenvio) {
          pedidospendenteenviolista.push(registrofirebase.pedidospendentesenvio);
        } */

          const clientepedidosalvo = JSON.parse(
            localStorage.getItem('CLIENTEPEDIDO')
          );
          const itenspedidosalvo = JSON.parse(
            localStorage.getItem('PRODUTOSCARRINHO')
          );
          const pedidogravado3 = {
            cliente: clientepedidosalvo,
            pedido: itenspedidosalvo,
            vendedor: vendedorlogado,
            tipopedido: stipopedido,
            dataentrega,
            numpedidrepresentante,
            observacaopedido
          };
          pedidospendenteenviolista.push(pedidogravado3);
          MySwal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'O pedido foi salvo. Você deverá sincronizar no botão relatorio de pedidos'
          });
          setisFetching(false);
          // AQUI VAI FICAR O ENVIO DO PEDIDO
          setisOpenConfirmacao(false);
          MySwal.fire({
            icon: 'success',
            title: 'Pedido',
            text: 'Pedido enviado com sucesso'
          });
          localStorage.setItem('CLIENTEPEDIDO', null);
          navigate('/app/dashboard');
        } else {
          // Something happened in setting up the request that triggered an Error
          const clientepedidosalvo = JSON.parse(
            localStorage.getItem('CLIENTEPEDIDO')
          );
          const itenspedidosalvo = JSON.parse(
            localStorage.getItem('PRODUTOSCARRINHO')
          );
          const pedidospendenteenvio = JSON.parse(
            localStorage.getItem('PEDIDOSPENDENTESENVIO')
          );
          const registrofirebase = null;


          const pedidogravado4 = {
            cliente: clientepedidosalvo,
            pedido: itenspedidosalvo,
            vendedor: vendedorlogado,
            tipopedido: stipopedido,
            dataentrega,
            numpedidrepresentante,
            observacaopedido
          };
          pedidospendenteenviolista.push(pedidogravado4);
          setisFetching(false);
          // AQUI VAI FICAR O ENVIO DO PEDIDO
          setisOpenConfirmacao(false);
          MySwal.fire({
            icon: 'success',
            title: 'Pedido',
            text: 'Pedido enviado com sucesso'
          });
          localStorage.setItem('CLIENTEPEDIDO', null);
          navigate('/app/dashboard');
        }
      });
  };

  React.useEffect(() => {
    localStorage.setItem('PEDIDOSPENDENTESENVIO', []);
    /*
    const localDB = new PouchDB('salesprod');
    const remoteDB = new PouchDB('http://localhost:5984/salesprod');
    const sync = localDB.sync(remoteDB, {
      live: true,
      retry: true
    }); */
  }, []);

  const handleSalvarPedido = () => {
    let stipopedido = '';
    const clientepedidosalvo = JSON.parse(
      localStorage.getItem('CLIENTEPEDIDO')
    );
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    const itenspedidosalvo = JSON.parse(
      localStorage.getItem('PRODUTOSCARRINHO')
    );
    const pedidospendenteenvio = JSON.parse(
      localStorage.getItem('PEDIDOSPENDENTESENVIO')
    );

    const dataentrega = localStorage.getItem('DATAENTREGA');
    const numpedidrepresentante = localStorage.getItem('PEDIDOREPRES');
    const observacaopedido = localStorage.getItem('OBSERVACAOPEDIDO');
    const tipopedido = JSON.parse(localStorage.getItem('TIPOPEDIDO'));

    if (tipopedido.tipopedido === 1) {
      stipopedido = 'Venda';
    } else {
      stipopedido = 'Bonificação';
    }

    const pedidogravado = {
      cliente: clientepedidosalvo,
      pedido: itenspedidosalvo,
      vendedor: vendedorlogado,
      tipopedido: stipopedido,
      dataentrega,
      numpedidrepresentante,
      observacaopedido
    };
    pedidospendenteenvio.push(pedidogravado);
    localStorage.setItem(
      'PEDIDOSPENDENTESENVIO',
      JSON.stringify(pedidospendenteenvio)
    );
    navigate('/app/pedidos');
  };

  const handleAcaoDialogo = async () => {
    setdesabilitabotaoenvio(true);
    FuncEnviarPedido();
  };

  const handleDialogConfirmacaoClose = () => {
    setisOpenConfirmacao(false);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      disableAutoFocus
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
        <p>Carrinho</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Container maxWidth={false}>
      <BackDrop open={isFetching} />
      <div className={classes.root}>
        <DialogoConfirmacao
          isOpen={isOpenConfirmacao}
          handleClose={handleDialogConfirmacaoClose}
          handleAcao={handleAcaoDialogo}
          disabled={desabilitabotaoenvio}
          title="Confirma"
          subtitle="Deseja enviar o pedido?"
          textoconfirmacao="Enviar"
        />
        <Box mt={3}>
          <div className={classes.grow}>
            <DialogTelaCheia
              isOpen={isOpen}
              handleClose={handleDialogClose}
              title="Lista Produtos"
              subtitle=""
              handleEnviarPedido={handleEnviarPedido}
              handleSalvarPedido={handleSalvarPedido}
            >
              <ListaItemPedido />
            </DialogTelaCheia>
            <AppBar position="static">
              <Toolbar>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Digite o Produto…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onInput={onInput}
                  />
                </div>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                  <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={handleMobileMenuOpen}
                  >
                    <Badge color="secondary">
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                </div>
                <div className={classes.sectionMobile}>
                  <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <Badge color="secondary">
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>
            {renderMobileMenu}
          </div>
        </Box>
      </div>
    </Container>
  );
};

PrimarySearchAppBar.propTypes = {
  onInput: PropTypes.string.isRequired,
  qtitems: PropTypes.number.isRequired
};

export default PrimarySearchAppBar;

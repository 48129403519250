/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import amplify_config from './amplify_config';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Logo from '../../components/Logo';
import BackDrop from '../../components/BackDrop';
import api from '../../services/api';


Amplify.configure(amplify_config);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(15),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
  },
}));

const apagaTodosValores = async () => localStorage.clear();
const MySwal = withReactContent(Swal);




const handlePrimeiroAcesso = async (email, senha, rota, loading) => {
  api.post(
    'pedidos-sales/v1/auth/users/',
    {
      email,
      username: email,
      password: senha
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then((response) => {
    console.log(response);
    MySwal.fire({
      icon: 'success',
      title: 'SEU CADASTRO FOI REALIZADO COM SUCESSO',
      text: 'Insira seus dados para acesso'
    });
    rota('/');
  }).catch((error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      console.log(error.response.status);
      if (error.response.status === 401) {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.detail
        });
        loading(false);
      }

      if (error.response.status === 400) {
        if (error.response.data.username) {
          MySwal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.username
          });
        }
        if (error.response.data.password) {
          MySwal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.password
          });
        }
        if (error.response.data.email) {
          MySwal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.email
          });
        }
        loading(false);
      }
      // console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });
};

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  // let usuarioamazon = null;
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [nome, setNome] = useState('');
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState(0);
  const [isFetching, setisFetching] = useState(false);
  const [reenviarCodigo, setreenviarCodigo] = useState(true);

  

  const handleClick = () => {
    signUp(nome, email, email, email, '+55'+phone, senha);
  };


  async function signUp(name, username, given_name, email, phone_number, password) {
    setisFetching(true);
    try {
        const { user } = await Auth.signUp({
            username,
            password,
            attributes: {
                name, 
                given_name,
                email,          // optional
                phone_number,   // optional - E.164 number convention
                // other custom attributes 
            },
            autoSignIn: { // optional - enables auto sign in after user is confirmed
                enabled: true,
            }
        });
        console.log(user);
        setisFetching(false);
    } catch (error) {

        if (error.message === 'Invalid phone number format.'){
          MySwal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Favor digitar o numero de telefone'
          });
        }

        if(error.message === 'An account with the given email already exists.'){
          MySwal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Codigo de validação enviado para o email: '+username
          });
          resendConfirmationCode();
        }
        console.log('error signing up:', error);
        setisFetching(false);
    }
    setreenviarCodigo(false);
  }


  async function confirmSignUp() {
    try {
      await Auth.confirmSignUp(email, code);
      navigate("/");
     
    } catch (error) {
        console.log('error confirming sign up', error);
    }
}

  async function resendConfirmationCode() {
    try {
        await Auth.resendSignUp(email);
        console.log('code resent successfully');
    } catch (err) {
        console.log('error resending code: ', err);
    }
}

  const location = useLocation();

  function myFunction(item) {
    if (item.substring(0, 8) === 'id_token') {
      // usuarioamazon = { tokenid: item.split('=')[1] };
    }
  }

  useEffect(() => {
    location.hash.replace('#', '').split('&').forEach(myFunction);
    apagaTodosValores();
    setreenviarCodigo(true);
  }, []);

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <BackDrop open={isFetching} />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >

        <Container maxWidth="sm">
          <div className={classes.paper}>
            <Logo />
            <h3>Informe seus dados abaixo</h3>
          </div>
          <form>       
          <TextField
              fullWidth
              label="Digite seu Nome"
              margin="normal"
              onChange={(e) => setNome(e.target.value)}
              name="nome"
              type="text"
              variant="outlined"
            />   
            <TextField
              fullWidth
              label="Digite seu Telefone"
              margin="normal"
              onChange={(e) => setPhone(e.target.value)}
              name="phone"
              type="Number"
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Digite seu Email"
              margin="normal"
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              name="email"
              type="email"
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Digite uma Senha"
              margin="normal"
              onChange={(e) => setSenha(e.target.value)}
              name="password"
              type="password"
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled={reenviarCodigo}
              label="Digite o codigo de verificação enviado por email"
              margin="normal"
              onChange={(e) => setCode(e.target.value.toLowerCase())}
              name="codevalidacao"
              type="text"
              variant="outlined"
            />
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                to="/dashboard"
                onClick={handleClick}
                size="large"
                variant="contained"
              >
                Cadastrar
              </Button>
            </Box>
            <Box my={2}>
              <Button
                color="primary"
                disabled={reenviarCodigo}
                fullWidth
                onClick={confirmSignUp}
                size="large"
                variant="contained"
              >
                Confirmar Código
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;

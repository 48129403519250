/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  makeStyles
} from '@material-ui/core';
import Swal from 'sweetalert2';
import Chip from '@material-ui/core/Chip';
import MUIDataTable, { TableFilterList } from 'mui-datatables';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import BackDrop from '../../../components/BackDrop';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

// eslint-disable-next-line react/prop-types
const CustomChip = ({ label, onDelete }) => {
  return (
    <Chip
      variant="outlined"
      color="secondary"
      label={label}
      onDelete={onDelete}
    />
  );
};

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

const CampanhaDataTable = () => {
  const [campanhas, setCampanha] = useState([]);
  const [isFetching, setisFetching] = useState(true);
  const [mensagem, setmensagem] = useState('Carregando Dados...');

  const navigate = useNavigate();

  const getCampanhas = async () => {
    // setisFetching(true);
    const response = await api.get(
      '/pedidos-sales/v2/pedido/campanhas',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Campanhas');
    setCampanha(response.data);
    setisFetching(false);
  };

  const getOrganizacaoVenda = async () => {
    const response = await api.get(
      '/api-pedidos/organizacaovenda',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Organização Venda');
    localStorage.setItem(
      'ORGANIZACAOVENDA',
      JSON.stringify(response.data.data)
    );
  };

  const getGestor = async () => {
    const response = await api.get(
      '/api-pedidos/gestor',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Gestor');
    localStorage.setItem(
      'GESTOR',
      JSON.stringify(response.data.data)
    );
  };

  const getGrupoClientes = async () => {
    const response = await api.get(
      '/api-pedidos/grupocliente',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Grupo Clientes');
    localStorage.setItem(
      'GRUPOCLIENTES',
      JSON.stringify(response.data.data)
    );
  };

  const getClientes = async () => {
    setisFetching(true);
    const response = await api.get(
      '/api-pedidos/emitente',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Clientes');
    localStorage.setItem(
      'CLIENTES',
      JSON.stringify(response.data.data)
    );
    setisFetching(false);
  };

  const getFamilia = async () => {
    const response = await api.get(
      '/api-pedidos/familia',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Familia');
    localStorage.setItem(
      'FAMILIA',
      JSON.stringify(response.data.data)
    );
  };

  const getRegiao = async () => {
    const response = await api.get(
      '/api-pedidos/regiao',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Região');
    localStorage.setItem(
      'REGIAO',
      JSON.stringify(response.data.data)
    );
  };

  const getProduto = async () => {
    const response = await api.get(
      '/api-pedidos/item',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Produto');
    localStorage.setItem(
      'PRODUTO',
      JSON.stringify(response.data.data)
    );
  };

  const getVendedores = async () => {
    const response = await api.get(
      '/api-pedidos/vendedor',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    const retornoMap = response.data.data.map((element) => {
      return { codigo: element.codigo, nome: element.nome };
    });
    setmensagem('Carregando Vendedores');
    localStorage.setItem(
      'VENDEDORES',
      JSON.stringify(retornoMap)
    );
  };

  const getRedes = async () => {
    const response = await api.get(
      '/api-pedidos/rede',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Redes');
    localStorage.setItem(
      'REDES',
      JSON.stringify(response.data.data)
    );
  };

  useEffect(() => {
    getClientes();
    getRedes();
    getOrganizacaoVenda();
    getGestor();
    getGrupoClientes();
    getProduto();
    getFamilia();
    getRegiao();
    getVendedores();
    getCampanhas();
  }, []);

  const EditarRegistro = async (e) => {
    localStorage.setItem('IDCAMPANHA', e);
    navigate('/app/detalhecampanha');
  };

  const ExcluirRegistro = async (e) => {
    let apagou = false;
    await Swal.fire({
      title: 'Deseja excluir a campanha?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      customClass: {
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        apagou = true;
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info');
      }
    });

    if (apagou) {
      await api.delete(
        `/pedidos-sales/v2/pedido/campanha/${String(e.rowData[0])}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
    }
    await getCampanhas();
    setisFetching(false);
  };

  const columns = [{
    name: 'id',
    options: {
      display: 'excluded',
      filter: false
    }
  },
  {
    name: 'nome',
    label: 'Nome',
    options: {
      filter: false
      // display: "excluded"
    }
  },
  {
    name: 'vigencia',
    label: 'Vigência',
    options: {
      filter: false
      // display: "excluded"
    }
  },
  {
    name: 'tipo',
    label: 'Tipo',
    options: {
      filter: true
      // display: "excluded"
    }
  },
  {
    name: 'vencida',
    label: 'Status',
    options: {
      customBodyRender: (value, tableMeta) => {
        if (tableMeta.rowData[4] === 'Valida') {
          return (
            <Chip
              label="Valida"
              color="primary"
              style={{ backgroundColor: '#04BF33' }}
            />
          );
        }
        return (
          <Chip
            label="Vencida"
            color="primary"
            style={{ backgroundColor: '#F23005' }}
          />
        );
      },
      filter: true
      // display: "excluded"
    }
  },
  {
    name: '',
    options: {
      customBodyRender: (value, tableMeta) => {
        return (
          <Chip
            icon={<FindInPageIcon />}
            label="Detalhe"
            onClick={() => { EditarRegistro(tableMeta.rowData[0]); }}
          />
        );
      },
      filter: false,
      sort: false,
      empty: true
      // display: "excluded"
    }
  },
  {
    name: '',
    options: {
      customBodyRender: (value, tableMeta) => {
        return (
          <Chip
            label="Excluir"
            color="secondary"
            onDelete={() => {
              ExcluirRegistro(tableMeta);
            }}
          />
        );
      },
      filter: false,
      sort: false,
      empty: true
      // display: "excluded"
    }
  }];

  const options = {
    filterType: 'checkbox',
    filter: true,
    print: false,
    download: true,
    viewColumns: false,
    selectableRows: 'none',
    rowsPerPage: 10,
    textLabels: {
      pagination: {
        next: 'Próximo',
        previous: 'Anterior',
        rowsPerPage: 'linhas por Pagina:',
        displayRows: 'de'
      },
      body: {
        noMatch: 'Nenhum registro encontrado',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenado por ${column.label}`
      },
      filter: {
        all: 'Todos',
        title: 'FILTRO',
        reset: 'LIMPAR'
      }
    }

  };

  return (
    <>
      <BackDrop open={isFetching} texto={mensagem} />

      <MUIDataTable
        columns={columns}
        data={campanhas}
        options={options}
        components={{
          TableFilterList: CustomFilterList,
        }}
      />
    </>
  );
};

const Results = ({ className, customers, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CampanhaDataTable />

    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;

import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const MensagemAlerta = (props) => {
  const {
    isOpen, handleClose, texto, nivel
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity={nivel} onClose={handleClose}>
          {texto}
        </Alert>
      </Snackbar>
    </div>
  );
};

MensagemAlerta.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  texto: PropTypes.string.isRequired,
  nivel: PropTypes.string.isRequired,
};

export default MensagemAlerta;

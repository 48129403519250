import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card,
  Paper,
  Grid,
  Button,
  makeStyles,
  TextField
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MUIDataTable, { TableFilterList } from 'mui-datatables';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BackDrop from '../../../components/BackDrop';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

// eslint-disable-next-line react/prop-types
const CustomChip = ({ label, onDelete }) => {
  return (
    <Chip
      variant="outlined"
      color="secondary"
      label={label}
      onDelete={onDelete}
    />
  );
};

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};


const getClientes = () => {
  const clientes = JSON.parse(localStorage.getItem('MEUSCLIENTES'));
  return clientes;
};


const ClienteDataTable = () => {
  const [clientes, setClientes] = useState([]);
  const [estados, setEstados] = useState([]);
  const [isFetching, setisFetching] = useState(false);
  const MySwal = withReactContent(Swal);


  const setEstadoCliente = (v) => {
    const lista = JSON.parse(localStorage.getItem('MEUSCLIENTES'));
    let dado = lista.filter((it) => {
      return it.estado === v.sigla;
    });
    setClientes(dado);

  }

  const handleAtualizarclientes = () => {
    setisFetching(true);
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    api.post(
      '/api-pedidos/vendedoremail',
      {
        email: vendedorlogado.email
      },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    ).then((response) => {
      localStorage.setItem('MEUSCLIENTES', JSON.stringify(response.data.data[0].clientes));
      setClientes(response.data.data[0].clientes);
    }).catch((error) => {
      console.log(error);
    });
    setisFetching(false);
    MySwal.fire({
      icon: 'success',
      title: 'Alerta',
      text: 'Clientes atualizados!'
    });
  };

  useEffect(() => {
    setisFetching(true);
    const retornoclientes = getClientes();
    const estados = [
      { "nome": "Acre", "sigla": "AC" },
      { "nome": "Alagoas", "sigla": "AL" },
      { "nome": "Amapá", "sigla": "AP" },
      { "nome": "Amazonas", "sigla": "AM" },
      { "nome": "Bahia", "sigla": "BA" },
      { "nome": "Ceará", "sigla": "CE" },
      { "nome": "Distrito Federal", "sigla": "DF" },
      { "nome": "Espírito Santo", "sigla": "ES" },
      { "nome": "Goiás", "sigla": "GO" },
      { "nome": "Maranhão", "sigla": "MA" },
      { "nome": "Mato Grosso", "sigla": "MT" },
      { "nome": "Mato Grosso do Sul", "sigla": "MS" },
      { "nome": "Minas Gerais", "sigla": "MG" },
      { "nome": "Pará", "sigla": "PA" },
      { "nome": "Paraíba", "sigla": "PB" },
      { "nome": "Paraná", "sigla": "PR" },
      { "nome": "Pernambuco", "sigla": "PE" },
      { "nome": "Piauí", "sigla": "PI" },
      { "nome": "Rio de Janeiro", "sigla": "RJ" },
      { "nome": "Rio Grande do Norte", "sigla": "RN" },
      { "nome": "Rio Grande do Sul", "sigla": "RS" },
      { "nome": "Rondônia", "sigla": "RO" },
      { "nome": "Roraima", "sigla": "RR" },
      { "nome": "Santa Catarina", "sigla": "SC" },
      { "nome": "São Paulo", "sigla": "SP" },
      { "nome": "Sergipe", "sigla": "SE" },
      { "nome": "Tocantins", "sigla": "TO" }

    ];
    setisFetching(false);
    setEstados(estados);
    if (retornoclientes.length < 200) {
      setClientes(retornoclientes);
    } else {
      setClientes([]);
    }
  }, []);

  const columns = [{
    name: 'fantasia',
    label: 'Nome',
    options: {
      filter: false
    }
  },
  {
    name: 'despagamento',
    label: 'Forma Pagamento',
    options: {
      filter: false
      // display: "excluded"
    }
  },
  {
    name: 'codtabela',
    label: 'Tabela',
    options: {
      filter: true
      // display: "excluded"
    }
  },
  {
    name: 'limitecredito',
    label: 'Limite Credito',
    options: {
      customBodyRender: (value) => {
        // console.log(tableMeta);
        return (
          <b>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2
            }).format(value.replace(',', '.'))}
          </b>
        );
      },
      filter: false
      // display: "excluded"
    }
  },
  {
    name: 'dataultimacompra',
    label: 'Data Ultima Compra',
    options: {
      customBodyRender: (value) => {
        // console.log(tableMeta);
        return (<b>{moment(value).format('DD/MM/YYYY')}</b>);
      },
      filter: false
    }
  },
  {
    name: 'rotaentrega',
    label: 'Rota Entrega',
    options: {
      filter: true
      // display: "excluded"
    },
  },
  {
    name: 'cnpj',
    label: 'CNPJ',
    options: {
      filter: true
      // display: "excluded"
    },
  },
  {
    name: 'endereco',
    label: 'Endereço',
    options: {
      filter: true
      // display: "excluded"
    },
  },
  {
    name: 'bairro',
    label: 'Bairro',
    options: {
      filter: true
      // display: "excluded"
    },
  },
  {
    name: 'cidade',
    label: 'Cidade',
    options: {
      filter: true
      // display: "excluded"
    },
  },
  {
    name: 'estado',
    label: 'Estado',
    options: {
      filter: true
      // display: "excluded"
    },
  }];

  const options = {
    filterType: 'checkbox',
    pagination: false,
    filter: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: 'auto',
    download: false,
    viewColumns: true,
    selectableRows: 'none',
    rowsPerPage: 10,
    responsive: 'simple',
    textLabels: {
      pagination: {
        next: 'Próximo',
        previous: 'Anterior',
        rowsPerPage: 'linhas por Pagina:',
        displayRows: 'de'
      },
      body: {
        noMatch: 'Favor selecionar o Estado',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenado por ${column.label}`
      },
      filter: {
        all: 'Todos',
        title: 'FILTRO',
        reset: 'LIMPAR'
      }
    }

  };

  return (
    <>
      <BackDrop open={isFetching} />
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              color="default"
              onClick={handleAtualizarclientes}
              startIcon={<CloudDownloadIcon />}
            >
              Atualizar Clientes
            </Button>
            <Autocomplete
              id="estado"
              options={estados}
              onChange={(event, v) => setEstadoCliente(v)}
              getOptionLabel={(option) => option.nome}
              renderInput={(params) => <TextField {...params} helperText="Estado" variant="outlined" />}
            />
          </Grid>
        </Grid>

      </Paper>
      <Divider />
      <MUIDataTable
        columns={columns}
        data={clientes}
        options={options}
        components={{
          TableFilterList: CustomFilterList,
        }}
      />
    </>
  );
};

const Results = ({ className, customers, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <ClienteDataTable />

    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;

/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import amplify_config from './amplify_config';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Link
} from '@material-ui/core';
import Page from 'src/components/Page';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Logo from '../../components/Logo';
import BackDrop from '../../components/BackDrop';
import api from '../../services/api';

Amplify.configure(amplify_config);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(15)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1)
  }
}));

const apagaTodosValores = async () => localStorage.clear();
const MySwal = withReactContent(Swal);

const getMotivoAprovacao = async () => {
  const response = await api.get('/api-pedidos/argumentosaprovacao', {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  localStorage.setItem('MOTIVOAPROVACAO', JSON.stringify(response.data));
};

const MetodosEntrar = async (email, rota, loading) => {
  apagaTodosValores();
  loading(true);

  const response = await api.post(
    '/api-pedidos/vendedoremail',
    { email },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  if (response.status === 404) {
    MySwal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Serviço indisponivel.'
    });
  } else {
    if (response.data.data) {
      const retornoapi = response.data.data[0];
      localStorage.setItem(
        'USUARIOLOGADO',
        JSON.stringify({
          // eslint-disable-next-line max-len
          perfil: retornoapi.perfil,
          codigo: retornoapi.codigo,
          nome: retornoapi.nome,
          email: retornoapi.email,
          emailsupervisor: retornoapi.emailsupervisor,
          emailgerente: retornoapi.emailgerente,
          ncc: retornoapi.ncc,
          clientescarteira: retornoapi.qtclientescarteira,
          qtpedidosmes: retornoapi.qtpedidosmescliente,
          supervisor: retornoapi.supervisor
        })
      );

      localStorage.setItem('MEUSCLIENTES', JSON.stringify(retornoapi.clientes));
      getMotivoAprovacao();

      const responsemeta = await api.post(
        '/api-pedidos/metavendedor',
        { codrep: retornoapi.codigo },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      localStorage.setItem('META', JSON.stringify(responsemeta.data.data));

      loading(false);
      rota('/app/dashboard');
    } else {
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Perfil de vendedor não disponivel para este email. Favor entrar em contato com o time interno de cadastro da Forno de Minas.'
      });
      loading(false);
    }
  }
};

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  // let usuarioamazon = null;
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [isFetching, setisFetching] = useState(false);

  async function signIn(email, password, rota, loading) {
    setisFetching(true);
    let username = email;
    let email_vendedor = email;

    try {
      const response = await api.get(`/admin-forno/api/substituto/${email}/email/`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('data retornada');
      console.log(response.data);
      if (response.data.length > 0) {
        username = email;
        email_vendedor = response.data[0].principal;
      } else {
        username = email;
        email_vendedor = email;
      }

    } catch (error) {
      if (error.response) {
        username = email;
        email_vendedor = email;
      }

    }

    Auth.signIn(
      username,
      password
    )
      .then((cognitoUser) => {

        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = cognitoUser.challengeParam;
          console.log('atributos requeridos');
          console.log(requiredAttributes);
          Auth.completeNewPassword(
            cognitoUser,
            password,
            {
              name: username
            }
          ).then(user => {

            MetodosEntrar(email_vendedor, rota, loading);
            console.log(user);
          }).catch(e => {
            console.log(e);
          });
        } else {

          MetodosEntrar(email_vendedor, rota, loading);

        }
      })
      .catch((err) => {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err
        });
      });

    setisFetching(false);
  }

  const handleClick = () => {
    signIn(email, senha, navigate, setisFetching);
  };

  const handlePrimeiroAcesso = () => {
    navigate('/primeiroacesso');
  };

  const handleEsqueciMinhaSenha = () => {
    navigate('/esqueciminhasenha');
  };

  const location = useLocation();

  function myFunction(item) {
    if (item.substring(0, 8) === 'id_token') {
      // usuarioamazon = { tokenid: item.split('=')[1] };
    }
  }

  useEffect(() => {
    location.hash.replace('#', '').split('&').forEach(myFunction);
    apagaTodosValores();
  }, []);

  return (
    <Page className={classes.root} title="Login">
      <BackDrop open={isFetching} />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <div className={classes.paper}>
            <Logo />
          </div>
          <form>
            <TextField
              fullWidth
              label="Email"
              margin="normal"
              onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
              name="email"
              type="email"
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Senha"
              margin="normal"
              onChange={(e) => setSenha(e.target.value)}
              name="password"
              type="password"
              variant="outlined"
            />
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                to="/dashboard"
                onClick={handleClick}
                size="large"
                variant="contained"
              >
                Entrar
              </Button>
            </Box>
            <Box my={2}>
              <Button
                color="secondary"
                fullWidth
                to="/primeiroacesso"
                onClick={handlePrimeiroAcesso}
                size="large"
                variant="contained"
              >
                Primeiro Acesso
              </Button>
            </Box>
            <Box my={2}>
              <Button
                color="secondary"
                fullWidth
                onClick={handleEsqueciMinhaSenha}
                to="/esqueciminhasenha"
                size="large"
                variant="contained"
              >
                Esqueci Minha Senha
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;

import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StoreIcon from '@material-ui/icons/Store';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import NavItem from './NavItem';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Vendedor',
  name: 'Cleber Carmo'
};

const items = [
  {
    href: '/app/dashboard',
    icon: DashboardIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/clientes',
    icon: GroupIcon,
    title: 'Clientes'
  },
  {
    href: '/app/titulos',
    icon: AccountBalanceIcon,
    title: 'Titulos'
  },
  {
    href: '/app/pedidos',
    icon: ReceiptIcon,
    title: 'Relatório de Pedidos'
  },
  {
    href: '/app/novopedido',
    icon: AddShoppingCartIcon,
    title: 'Novo Pedido'
  },
  {
    href: '/app/perfil',
    icon: PersonIcon,
    title: 'Pefil'
  }
];

const itemssupervisoradm = [
  {
    href: '/app/campanha',
    icon: StoreIcon,
    title: 'Campanha'
  },
  {
    href: '/app/perfil',
    icon: PersonIcon,
    title: 'Pefil'
  }
];

const itemsvdi = [
  {
    href: '/app/dashboard',
    icon: DashboardIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/vdi',
    icon: StoreIcon,
    title: 'Gestão'
  },
  {
    href: '/app/clientes',
    icon: GroupIcon,
    title: 'Clientes'
  },
  {
    href: '/app/titulos',
    icon: AccountBalanceIcon,
    title: 'Titulos'
  },
  {
    href: '/app/pedidos',
    icon: ReceiptIcon,
    title: 'Relatório de Pedidos'
  },
  {
    href: '/app/novopedido',
    icon: AddShoppingCartIcon,
    title: 'Novo Pedido'
  },
  {
    href: '/app/perfil',
    icon: PersonIcon,
    title: 'Pefil'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [usuario, setUsuario] = useState(null);

  useEffect(() => {
    setUsuario(JSON.parse(localStorage.getItem('USUARIOLOGADO')));
    if (openMobile && onMobileClose) {
      onMobileClose();
    }   
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/perfil"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {usuario !== null && usuario.nome}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>

          {usuario !== null && usuario.perfil && (

            usuario.perfil === '4' && (

              items.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))
            )

          )}

          {usuario !== null && usuario.perfil && (

            usuario.perfil === '3' && (

              items.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))
            )

          )}

          { usuario !== null && usuario.perfil && (

            usuario.perfil === '1' && (

              itemssupervisoradm.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))

            )

          )}

          { usuario !== null && usuario.perfil && (

            usuario.perfil === '2' && (

              itemsvdi.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))

            )

          )}

        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;

/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import amplify_config from './amplify_config';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Logo from '../../components/Logo';
import BackDrop from '../../components/BackDrop';
import api from '../../services/api';

Amplify.configure(amplify_config);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(15)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1)
  }
}));

const apagaTodosValores = async () => localStorage.clear();
const MySwal = withReactContent(Swal);

const EsqueciMinhaSenha = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  // let usuarioamazon = null;
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [code, setCode] = useState('');
  const [isFetching, setisFetching] = useState(false);
  const [reenviarCodigo, setreenviarCodigo] = useState(false);

  async function  forgotPasswordSubmit() {


    setisFetching(true);
      Auth.forgotPasswordSubmit(email, code, senha)
    .then(data => console.log(data))
    .catch(err => console.log(err));
     
      setisFetching(false);
      setreenviarCodigo(false);
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Sua senha foi alterada com sucesso'
      });
      navigate('/');
    }
    

  async function   forgotPassword() {
    try {
      Auth.forgotPassword(email)
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
        setreenviarCodigo(true);
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Foi enviado um código de validação para o email: '+email
        });
    } catch (error) {
      console.log('error confirming sign up', error);
    }
    setreenviarCodigo(false);
  }

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(email);
      console.log('code resent successfully');
    } catch (err) {
      console.log('error resending code: ', err);
    }
  }

  const location = useLocation();

  function myFunction(item) {
    if (item.substring(0, 8) === 'id_token') {
      // usuarioamazon = { tokenid: item.split('=')[1] };
    }
  }

  useEffect(() => {
    location.hash.replace('#', '').split('&').forEach(myFunction);
    apagaTodosValores();
    setreenviarCodigo(true);
  }, []);

  return (
    <Page className={classes.root} title="Login">
      <BackDrop open={isFetching} />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <div className={classes.paper}>
            <Logo />
            <h3>Informe seu email e clique em esqueci minha senha</h3>
          </div>
          <form>
          <TextField
              fullWidth
              label="Digite seu Email"
              margin="normal"
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              name="email"
              type="email"
              variant="outlined"
            />
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                onClick={forgotPassword}
                size="large"
                variant="contained"
              >
                Esqueci Minha Senha
              </Button>
            </Box>
            <TextField
              fullWidth
              disabled={reenviarCodigo}
              label="Digite o codigo de verificação enviado por email"
              margin="normal"
              onChange={(e) => setCode(e.target.value.toLowerCase())}
              name="codevalidacao"
              type="text"
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled={reenviarCodigo}
              label="Digite uma nova Senha"
              margin="normal"
              onChange={(e) => setSenha(e.target.value)}
              name="password"
              type="password"
              variant="outlined"
            />

            <Box my={2}>
              <Button
                color="primary"
                disabled={reenviarCodigo}
                fullWidth
                onClick={forgotPasswordSubmit}
                size="large"
                variant="contained"
              >
                Cadastrar uma nova senha'
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Page>
  );
};

export default EsqueciMinhaSenha;

/* eslint-disable max-len */
import React, { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import StorefrontIcon from '@material-ui/icons/Storefront';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import CommentIcon from '@material-ui/icons/Comment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Divider from '@material-ui/core/Divider';
import { Alert, AlertTitle } from '@material-ui/lab';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import api from '../../../services/api';
import MenuTemporario from '../../../components/MenuTemporario';
import BackDrop from '../../../components/BackDrop';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '96ch',
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function SelecaoCampanha() {
  const classes = useStyles();
  const [isOpen, setisOpen] = React.useState(false);
  const [tipobonificacao, setTipoBonificacao] = React.useState(false);
  const [checked, setChecked] = React.useState([0]);
  const [campanhas, setCampanhas] = React.useState([]);
  const [isFetching, setisFetching] = React.useState(false);
  const [itenscampanha, setItensCampanha] = React.useState([]);
  const MySwal = withReactContent(Swal);
  // const listacampanha = useSelector((state) => state.campanhas);

  useEffect(() => {
    setCampanhas({
      campanha: [],
      itens: []
    });
  }, []);

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const handleSelecionarCampanha = (value) => (event) => {
    //  console.log(value);
    //  console.log(event.target.checked);
    const campanhaliberadas = JSON.parse(localStorage.getItem('CAMPANHASLIBERADAS'));
    const objIndex = campanhaliberadas.findIndex(((obj) => obj.codigo === value.codigo));
    campanhaliberadas[objIndex].utiliza = event.target.checked;

    localStorage.setItem('CAMPANHASLIBERADAS', JSON.stringify(campanhaliberadas));

    // objIndex = myArray.findIndex((obj => obj.id == 1));
  };

  const handleCloseMenuRapido = () => {
    setisOpen(false);
  };

  const ItemCampanha = (value) => async () => {
    setisFetching(true);
    const response = await api.post(
      '/pedidos-sales/v2/pedido/getitemcampanha',
      { codigo: value.codigo },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setItensCampanha(response.data);
    localStorage.setItem('ITENSCAMPANHA', JSON.stringify(response.data));
    //  console.log(itenscampanha);
    setisOpen(true);
    setisFetching(false);
  };

  const handleCampanha = async () => {
    setisFetching(true);
    const p = JSON.parse(localStorage.getItem('TIPOPEDIDO'));
    if (p) {
      //  console.log(p);
      if (p.tipopedido === '') {
        setCampanhas([]);
        //  console.log('Favor selecionar o tipo do pedido');
      }
      if (p.tipopedido === 2) {
        setTipoBonificacao(true);
        setCampanhas([]);
        //  console.log('Campanha indisponivel para bonificacao');
      }
      if (p.tipopedido === 1) {
        setCampanhas([]);
        const cliente = JSON.parse(localStorage.getItem('CLIENTEPEDIDO'));
        if (cliente) {
          const usuariologado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
          const produtostabela = JSON.parse(localStorage.getItem('PRODUTOSPEDIDO'));
          const response = await api.post(
            '/pedidos-sales/v2/pedido/getcampanhacombinacao',
            { tabela: produtostabela.codtabela, mensagem: [cliente.fantasia, usuariologado.nome, usuariologado.supervisor, cliente.grupocliente, cliente.regiao, cliente.codestabelecimento, cliente.canalvenda.toUpperCase(), cliente.rede, usuariologado.nome] },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }

          );
          //  console.log('a');
          if (response.data.campanha.length === 0) {
            MySwal.fire({
              icon: 'warning',
              title: 'Campanha',
              text: 'Não existe campanha cadastrada'
            });
          }
          setCampanhas(response.data);
          localStorage.setItem('CAMPANHASLIBERADAS', JSON.stringify(response.data.campanha));
          setisFetching(false);
        }
      }
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      <div>
        <BackDrop open={isFetching} />
        <Button
          variant="contained"
          color="primary"
          hidden={tipobonificacao}
          className={classes.button}
          onClick={handleCampanha}
          startIcon={<StorefrontIcon />}
        >
          Campanhas
        </Button>
      </div>
      <div>
        <List className={classes.root}>

          {campanhas.campanha
      && (
      <div>
        {campanhas.campanha.map((value) => {
          return (
            <ListItem key={value.nome} role={undefined} dense button onClick={handleToggle(value.nome)}>
              <ListItemText
                primary={value.nome}
                secondary={(
                  <>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                    >
                      <DateRangeIcon />
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      />
                      {value.vigencia}
                    </div>
                  </>
          )}
              />

              <ListItemSecondaryAction>
                <FormControlLabel
                  control={(
                    <Checkbox
                      // onChange={handleSelecionarCampanha(value)}
                      onClick={handleSelecionarCampanha(value)}
                      name="escolhacampanha"
                      color="primary"
                    />
        )}
                  label=""
                />
                <IconButton onClick={ItemCampanha(value)} color="primary" component="span" aria-label="comments">
                  <CommentIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </div>
      )}
        </List>
      </div>
      <div>
        <MenuTemporario isOpen={isOpen} handleClose={handleDialogClose}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleCloseMenuRapido} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h5" className={classes.title}>
                Produtos Participantes
              </Typography>
            </Toolbar>
          </AppBar>
          <Alert severity="success">
            <AlertTitle>{itenscampanha.campanha}</AlertTitle>
            Siga as regras abaixo —
            {' '}
            <strong>e participe!</strong>
          </Alert>
          <List className={classes.root}>
            {itenscampanha.itens
&& (
<div>
  {itenscampanha.itens.map((value) => {
    return (
      <>
        <ListItem button>
          <ListItemText primary={(
            <>
              <Alert severity="info">
                <AlertTitle>{value.bonificacao}</AlertTitle>
                {' '}
                {value.quantidade}
                {' '}
                {'X'}
                {' '}
                {value.produto}
                {' '}
                <strong>e ganhe</strong>
              </Alert>
            </>
)}
          />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary={`Desconto Comercial:  ${value.desconto_comercial} %`} />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary={`Desconto Adicional: ${value.desconto_adicional} %`} />
        </ListItem>
        <Divider />
      </>
    );
  })}
</div>
)}
            ;

          </List>
        </MenuTemporario>
      </div>
    </>
  );
}

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56
  }
}));
/*
const getMetaRealizada = () => {
  const meta = JSON.parse(localStorage.getItem('META'));
  let resultado = 0;
  resultado = meta.map((li) => Number(String(li.vlrealizado).replace(',', '.'))).reduce((sum, val) => sum + val, 0);
  return resultado;
};

const getMetaPrevista = () => {
  const meta = JSON.parse(localStorage.getItem('META'));
  let resultado = 0;
  resultado = meta.map((li) => Number(String(li.vlprevisto).replace(',', '.'))).reduce((sum, val) => sum + val, 0);
  return resultado;
};
*/
const TasksProgress = ({ className, ...rest }) => {
  const classes = useStyles();
  // const [vlrealizada, setVlrealizada] = useState(0);
  // const [vlprevisto, setVlprevisto] = useState(0);
  const [porcentagem, setPorcentagem] = useState(0);
  const [totalclientes, setTotalClientes] = useState(0);
  const [totalpedidosmes, setTotalPedidosMes] = useState(0);

  useEffect(() => {
    const usuariologado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    /* console.log(getMetaRealizada);
    console.log(getMetaPrevista); */
    // setVlrealizada(getMetaRealizada);
    // setVlprevisto(getMetaPrevista);
    // const vlporcentagem = ((vlrealizada / vlprevisto)) * 100;
    setPorcentagem(Math.trunc(Number(usuariologado.ncc.replaceAll(',', '.'))));
    setTotalClientes(usuariologado.clientescarteira);
    setTotalPedidosMes(usuariologado.qtpedidosmes);
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              NCC
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {porcentagem}
              {' '}
              %
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Clientes
            </Typography>
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {totalclientes}
              {' '}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Realizado
            </Typography>
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {totalpedidosmes}
              {' '}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InsertChartIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={3}>
          <LinearProgress
            value={porcentagem}
            variant="determinate"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

TasksProgress.propTypes = {
  className: PropTypes.string
};

export default TasksProgress;

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [usuario, setUsuario] = useState(null);
  useEffect(() => {
    setUsuario(JSON.parse(localStorage.getItem('USUARIOLOGADO')));
  }, []);
  const [values, setValues] = useState({
    firstName: 'Cleber',
    lastName: 'Carmo',
    email: 'cleber.carmo@fornodeminas.com.br',
    phone: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Vendedor"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled
                helperText="Usuário"
                label="Nome"
                name="usuario"
                onChange={handleChange}
                required
                value={usuario !== null && usuario.nome}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled
                label="Código"
                name="codigo"
                onChange={handleChange}
                required
                value={usuario !== null && usuario.codigo}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={usuario !== null && usuario.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled
                label="NCC"
                name="ncc"
                onChange={handleChange}
                type="number"
                value={usuario !== null && usuario.ncc}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;

/* eslint-disable max-len */
/* eslint-disable no-unreachable */
/* eslint-disable vars-on-top */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  doc, updateDoc, getDoc, getDocFromCache
} from 'firebase/firestore';
// import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Card,
  TextField,
  FormControl,
  Grid,
  MenuItem,
  Typography,
  Switch,
  FormControlLabel
} from '@material-ui/core';
// import { format } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from 'react-select';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import AcessoRapido from '../../../components/AcessoRapido';
import BackDrop from '../../../components/BackDrop';
import api from '../../../services/api';
// import { addcampanha } from '../../../store/campanha/campanha';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
  },
  formControl: {
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const customStyles = {
  control: (base, state) => ({
    ...base,
    // background: '#fff !important',
    // match with the menu
    // borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
    // Overwrittes the different states of border
    // borderColor: state.isFocused ? 'yellow' : 'green',
    // Removes weird border around container
    width: '400px',
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? 'red' : 'blue'
    }
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // background: '#efe4e4 !important',
    // kill the gap
    marginTop: 0
  }),
  menuList: (base) => ({
    ...base,
    // background: '#efe4e4 !important',
    // kill the white space on first and last option
    padding: 0
  })
};

const diasrota = [
  { texto: 'Segunda', dia: '2' },
  { texto: 'Terça', dia: '3' },
  { texto: 'Quarta', dia: '4' },
  { texto: 'Quinta', dia: '5' },
  { texto: 'Sexta', dia: '6' },
];

const FormPedido = () => {
  const classes = useStyles();
  /*
  const [state, setState] = useState({
    TipoPedido: 'v',
    Mensagem: 'Pedido de Venda!',
    Nivel: 'info'
  }); */
  // const dispatch = useDispatch();
  const [clientes, setClientes] = useState([]);
  const [motivosforarota, setMotivoForaRota] = useState([]);
  const [motivosbonificacao, setMotivoBonificacao] = useState([]);
  const [codcliente, setCodCliente] = React.useState(null);
  const [mensagemalerta, setMensagemAlerta] = React.useState('');
  const [escolhamotivobonificao, setEscolhamotivobonificao] = React.useState('');
  const [escolhaforarota, setEscolhaforarota] = React.useState('');
  const [pedidoforarota, setPedidoForaRota] = React.useState(false);
  const [usafretenopedido, setUsaFreteNoPedido] = React.useState(false);
  const [escondediaentrega, setEscondeDiaEntrega] = React.useState(true);
  const [escondeforarota, setEscondeForaRota] = React.useState(true);
  const [escondebonificacao, setEscondeBonificao] = React.useState(true);
  const [pedrepres, setPedRepres] = React.useState(null);
  const [escolheucliente, setEscolheucliente] = React.useState(true);
  const [isFetching, setisFetching] = useState(false);
  const [desativacliente, setdesativacliente] = useState(true);
  // const [desativadataentrega, setdesativadataentrega] = useState(true);
  // const formatadata = (data) => format(data, "yyyy'-'MM'-'dd");
  const [dataentrega, setDataEntrega] = React.useState(null);
  const [observacao, setObservacao] = React.useState(null);
  // const [dataentregaformatada, setentregaformatada] = React.useState(formatadata(moment().add(1, 'days').toDate()));
  const getEstoqueProduto = async () => {
    const response = await api.get(
      '/api-pedidos/estoqueall',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    localStorage.setItem(
      'ESTOQUEPRODUTO',
      JSON.stringify(response.data.data)
    );
  };

  useEffect(() => {
    // --- FRETE NO PEDIDO INICIA COMO FALSO.
    setUsaFreteNoPedido(false);
    setEscondeDiaEntrega(true);
    localStorage.setItem('USAFRETENOPEDIDO', false);
    // --- FIM FRETE NO PEDIDO INICIA COMO FALSO.
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    localStorage.setItem('PENDENCIAAPROVACAO', 'nao');
    async function fetchData() {

      if (vendedorlogado.perfil === '4') {
        const response = await api.get(
          '/api-pedidos/todosclientes',
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        setClientes(response.data.data);
        setEscondeDiaEntrega(true);
      } else {
        setClientes(JSON.parse(localStorage.getItem('MEUSCLIENTES')));
      }
    }

    // INICIA O PEDIDO DE REPRESENTANTE COM BRANCO.
    localStorage.setItem('PEDIDOREPRES', '');
    localStorage.setItem('OBSERVACAOPEDIDO', '');

    const motivosaprovacao = JSON.parse(localStorage.getItem('MOTIVOAPROVACAO'));
    const forarota = motivosaprovacao.filter((it) => {
      return it.motivodescricao === 'Fora de Rota';
    });
    const bonificacao = motivosaprovacao.filter((it) => {
      return it.motivodescricao === 'Bonificação';
    });
    setMotivoForaRota(forarota);
    setMotivoBonificacao(bonificacao);
    getEstoqueProduto();

    const dataentregas = moment(new Date()).format('DD/MM/YYYY');
    localStorage.setItem('DATAENTREGA', dataentregas);
    localStorage.setItem('ESCOLHEUDATAENTREGA', 'nao');
    localStorage.setItem('ARGUMENTOSAPROVACAO', JSON.stringify({ bonificacao: '', forarota: '' }));
    // setEscolheucliente(true);
    fetchData();
  }, []);

  /*
  const handleChange = (event) => {
    if (event.target.checked) {
      setState({
        ...state, [event.target.name]: event.target.checked, Mensagem: 'Pedido de Bonificação!', Nivel: 'warning'
      });
    } else {
      setState({
        ...state, [event.target.name]: event.target.checked, Mensagem: 'Pedido de Venda!', Nivel: 'info'
      });
    }
  };
*/

  const handleChangeFreteNoPedido = (event) => {
    localStorage.setItem('USAFRETENOPEDIDO', event.target.checked);
    setUsaFreteNoPedido(event.target.checked);
  };

  const handleTipoPedido = async (value) => {
    if (value === 2) {
      setEscondeBonificao(false);
      localStorage.setItem('PENDENCIAAPROVACAO', 'sim');
    } else {
      setEscondeBonificao(true);
      localStorage.setItem('ARGUMENTOSAPROVACAO', JSON.stringify({ bonificacao: '', forarota: escolhaforarota }));
      setEscolhamotivobonificao('');
      localStorage.setItem('PENDENCIAAPROVACAO', 'nao');
    }

    if (value === '') {
      setdesativacliente(true);
      // setdesativadataentrega(true);
    } else {
      // setdesativadataentrega(false);
      setdesativacliente(false);
      const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));

      localStorage.setItem(
        'TIPOPEDIDO',
        JSON.stringify({ tipopedido: value })
      );
    }
  };

  function hasThanMore(cliente, dia) {
    return cliente.rotaentrega.indexOf(dia) !== -1;
  }

  const handleNumPedidoRepres = async (value) => {
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    setPedRepres(value);

    localStorage.setItem('PEDIDOREPRES', value);
  };

  const handleObservacaoPedido = async (value) => {
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    setObservacao(value);

    localStorage.setItem('OBSERVACAOPEDIDO', value);
  };

  const handleDiaRota = (value) => {
    if (value.length === 0) {
      setClientes(JSON.parse(localStorage.getItem('MEUSCLIENTES')));
    } else {
      let resultado = [];
      const clientesl = JSON.parse(localStorage.getItem('MEUSCLIENTES'));
      value.forEach((p) => {
        // console.log(p);
        // resultado = clientesl.filter((cliente) => hasThanMore(cliente, p.dia));
        resultado = resultado.concat(clientesl.filter((cliente) => hasThanMore(cliente, p.dia)));
      });
      setClientes(resultado);
    }
  };

  /*
  function getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  */

  const handleBonificao = async (e) => {
    if (e) {
      setEscolhamotivobonificao(e.descricao);
      localStorage.setItem('ARGUMENTOSAPROVACAO', JSON.stringify({ bonificacao: e.descricao, forarota: escolhaforarota }));
    } else {
      setEscolhamotivobonificao('');
      localStorage.setItem('ARGUMENTOSAPROVACAO', JSON.stringify({ bonificacao: '', forarota: escolhaforarota }));
    }
  };

  const handleForaRota = async (e) => {
    if (e) {
      setEscolhaforarota(e.descricao);
      localStorage.setItem('USAFRETENOPEDIDO', 'true');
      localStorage.setItem('ARGUMENTOSAPROVACAO', JSON.stringify({ forarota: e.descricao, bonificacao: escolhamotivobonificao }));
    } else {
      setEscolhaforarota('');
      localStorage.setItem('USAFRETENOPEDIDO', 'false');
      localStorage.setItem('ARGUMENTOSAPROVACAO', JSON.stringify({ bonificacao: escolhamotivobonificao, forarota: '' }));
    }
  };

  const handleCliente = async (e) => {
    localStorage.setItem('PENDENCIAAPROVACAO', 'nao');
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    // const csrftoken = getCookie('csrftoken');
    setPedidoForaRota(false);
    // const d = new Date();
    // const n = d.getDay() + 1;
    setCodCliente(e);
    if (e !== null) {
      setisFetching(true);
      const response = await api.post(
        '/api-pedidos/tabela',
        { codigo: e.codtabela },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );



      localStorage.setItem(
        'PRODUTOSPEDIDO',
        JSON.stringify(response.data.data[0])
      );
      localStorage.setItem(
        'CLIENTEPEDIDO',
        JSON.stringify(e)
      );

      /*
      const usuariologado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
      const response2 = await api.post(
        '/pedidos-sales/v2/pedido/getcampanhacombinacao',
        { mensagem: [e.fantasia, usuariologado.nome, usuariologado.supervisor, e.grupocliente, e.regiao, e.codestabelecimento, e.canalvenda.toUpperCase(), e.rede] },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken
          }
        }

      );

      localStorage.setItem('CAMPANHASLIBERADAS', JSON.stringify(response2.data.campanha));

      dispatch(addcampanha(response2.data)); */

      setisFetching(false);
      setEscolheucliente(true);
      /*
      console.log(String(e.rotaentrega).indexOf(String(n)));
      if (String(e.rotaentrega) === '') setMensagemAlerta('Sem rota cadastrada');
      if (String(e.rotaentrega).indexOf(String(n)) === -1) {
        setPedidoForaRota(true);
        setMensagemAlerta('Pedido Fora de Rota');
      } else {
        setPedidoForaRota(false);
      }
      */
    }
  };

  const handleDataEntrega = async (e) => {
    // const resultado = formatadata(e);
    const n = e.getDay() + 1;
    setdesativacliente(false);

    if (codcliente) {
      if (String(codcliente.rotaentrega) === '') setMensagemAlerta('Sem rota cadastrada');
      if (String(codcliente.rotaentrega).indexOf(String(n)) === -1 && codcliente.rotaentrega !== '') {
        setPedidoForaRota(true);
        setEscondeForaRota(false);
        localStorage.setItem('PENDENCIAAPROVACAO', 'sim');
        setUsaFreteNoPedido(true);
        setMensagemAlerta('Pedido Fora de Rota');
      } else {
        localStorage.setItem('ARGUMENTOSAPROVACAO', JSON.stringify({ bonificacao: escolhamotivobonificao, forarota: '' }));
        setEscolhaforarota('');
        setUsaFreteNoPedido(false);
        setEscondeForaRota(true);
        setPedidoForaRota(false);
      }
      // setentregaformatada(resultado);
      localStorage.setItem('ESCOLHEUDATAENTREGA', 'sim');
      setDataEntrega(e);
      const dataentregas = moment(e).format('DD/MM/YYYY');
      localStorage.setItem('DATAENTREGA', dataentregas);
      const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));

    }
  };

  return (
    <div className={classes.root}>
      <Card>
        <BackDrop open={isFetching} />
        <Box
          display="flex"
          justifyContent="flex-start"
          p={1}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <FormControlLabel
                control={(
                  <Switch
                    checked={usafretenopedido}
                    onChange={handleChangeFreteNoPedido}
                    name="usafretenopedido"
                    color="primary"
                  />
                )}
                label="Cliente concorda com a cobrança do Frete"
              />
            </Grid>

            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >

              {codcliente
                && (
                  <>
                    <Alert severity="info">
                      <AlertTitle>LeadTime</AlertTitle>
                      <strong>{codcliente.leadtime}</strong>
                    </Alert>
                  </>
                )}
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >

              {codcliente
                && (
                  <>
                    <Alert severity="info">
                      <AlertTitle>Rota de Entrega</AlertTitle>
                      <strong>{codcliente.rotaentrega}</strong>
                    </Alert>
                  </>
                )}
            </Grid>

            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              {codcliente
                && (
                  <>
                    <Alert severity="info">
                      <AlertTitle>Data Ultima Compra</AlertTitle>
                      <strong>{moment(codcliente.dataultimacompra).format('DD/MM/YYYY')}</strong>
                    </Alert>
                  </>
                )}

              {pedidoforarota
                && (
                  <>
                    <Alert severity="error">
                      <AlertTitle>{mensagemalerta}</AlertTitle>
                    </Alert>
                  </>
                )}
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              {codcliente && codcliente.tituloaberto === 'SIM'
                && (
                  <>
                    <Alert severity="error">
                      <AlertTitle>Cliente Possui Título(s) Aberto.</AlertTitle>
                    </Alert>

                  </>
                )}

            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              {codcliente
                && (
                  <Alert severity="warning">
                    <AlertTitle>Saldo de Crédito Disponivel</AlertTitle>
                    {' '}
                    <strong>
                      {
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2
                        }).format(codcliente.limitecredito.replace(',', '.'))
                      }
                    </strong>
                  </Alert>
                )}
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              {codcliente
                && (
                  <Alert severity="info">
                    <AlertTitle>Vendedor</AlertTitle>
                    {' '}
                    <strong>
                      {codcliente.nomevendedor}
                    </strong>
                  </Alert>
                )}
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              {codcliente
                && (
                  <>
                    <Alert severity="info">
                      <AlertTitle>Tabela de Preço</AlertTitle>
                      <strong>{codcliente.codtabela}</strong>
                    </Alert>
                  </>
                )}
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              {codcliente
                && (
                  <Alert severity="info">
                    <AlertTitle>Endereço</AlertTitle>
                    {' '}
                    <strong>
                      {codcliente.endereco}
                    </strong>
                  </Alert>
                )}
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              {codcliente
                && (
                  <Alert severity="info">
                    <AlertTitle>Bairro</AlertTitle>
                    {' '}
                    <strong>
                      {codcliente.bairro}
                    </strong>
                  </Alert>
                )}
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              {codcliente
                && (
                  <Alert severity="info">
                    <AlertTitle>Cidade</AlertTitle>
                    {' '}
                    <strong>
                      {codcliente.cidade}
                    </strong>
                  </Alert>
                )}
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              {codcliente
                && (
                  <Alert severity="info">
                    <AlertTitle>Estabelecimento de Carga</AlertTitle>
                    {' '}
                    <strong>
                      {codcliente.codestabelecimento}
                    </strong>
                  </Alert>
                )}
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography variant="body1" gutterBottom>Dia de Entrega</Typography>
              <Select
                isMulti
                styles={customStyles}
                getOptionLabel={(option) => option.texto}
                getOptionValue={(option) => option.dia}
                onChange={(v) => handleDiaRota(v)}
                options={diasrota}
                isHidden={escondediaentrega}
                label="Rota"
              />

            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              <TextField
                id="pedrepres"
                helperText="Nr. Pedido Cliente"
                onChange={(e) => handleNumPedidoRepres(e.target.value)}
                value={pedrepres}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              <FormControl row variant="outlined" className={classes.formControl}>
                <TextField
                  id="tipo-pedido"
                  variant="outlined"
                  select
                  onChange={(e) => handleTipoPedido(e.target.value)}
                  helperText="Escolha o Tipo"
                >
                  <MenuItem value={1} key={0}>Venda</MenuItem>
                  <MenuItem value={2} key={2}>Bonificacao</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Autocomplete
                id="motivobonificacao"
                options={motivosbonificacao}
                hidden={escondebonificacao}
                onChange={(event, v) => handleBonificao(v)}
                getOptionLabel={(option) => option.descricao}
                renderInput={(params) => <TextField {...params} helperText="Motivo Bonificação" variant="outlined" />}
              />

              <Autocomplete
                id="motivoforarota"
                options={motivosforarota}
                hidden={escondeforarota}
                onChange={(event, v) => handleForaRota(v)}
                getOptionLabel={(option) => option.descricao}
                renderInput={(params) => <TextField {...params} helperText="Motivo Fora Rota" variant="outlined" />}
              />

              <Autocomplete
                id="cliente"
                options={clientes}
                disabled={desativacliente}
                onChange={(event, v) => handleCliente(v)}
                value={codcliente}
                getOptionLabel={(option) => option.fantasia}
                renderInput={(params) => <TextField {...params} helperText="Cliente" variant="outlined" />}
              />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={ptLocale}
              >
                <DatePicker
                  margin="normal"
                  label="Data Entrega"
                  inputVariant="outlined"
                  hidden={desativacliente}
                  id="dataentrega"
                  format="dd/MM/yyyy"
                  value={dataentrega}
                  minDate={moment().add(1, 'days')}
                  onChange={handleDataEntrega}
                />
              </MuiPickersUtilsProvider>

            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              <TextField
                id="observacao"
                helperText="Observação"
                hidden={desativacliente}
                multiline
                onChange={(e) => handleObservacaoPedido(e.target.value)}
                value={observacao}
                variant="outlined"
                labelWidth={60}
              />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              <AcessoRapido isOpen={escolheucliente} />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </div>
  );
};

export default FormPedido;

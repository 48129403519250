/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TableContainer from '@material-ui/core/TableContainer';
import {
  Card,
  CardHeader,
  Tooltip,
  Divider,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Chip,
  Avatar,
  Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ListAltIcon from '@material-ui/icons/ListAlt';
import api from '../../../services/api';
import MenuTemporario from '../../../components/MenuTemporario';
import BackDrop from '../../../components/BackDrop';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '96ch',
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function UltimosPedidos() {
  const classes = useStyles();
  const [isOpen3, setisOpen3] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  // const [desativabotaosincronizar, setdesativabotaosincronizar] = useState(false);
  const [pedidospendentes, setPedidosPendentes] = useState([]);
  const [itenspedidohoje, setItensPedidoHoje] = useState([]);
  const [isFetching, setisFetching] = useState(true);

  useEffect(() => {
    setisFetching(false);
  }, []);

  const handleCloseMenuRapido2 = () => {
    setisOpen3(false);
  };

  const ItensPedido2 = (e) => {
    setItensPedidoHoje(e);
    //  console.log(e);
    setisOpen3(true);
  };

  const GetUltimosPedidos = async () => {
    let encontrou = false;
    const MySwal = withReactContent(Swal);
    if (JSON.parse(localStorage.getItem('CLIENTEPEDIDO'))) {
      setisFetching(true);
      console.log(isOpen);
      const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
      const clientepedido = JSON.parse(localStorage.getItem('CLIENTEPEDIDO'));
      if (clientepedido.codigo) {
        const response = await api.post(
          '/api-pedidos/ultimospedidoscliente',
          {
            codvendedor: vendedorlogado.codigo,
            codcliente: clientepedido.codigo
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        if (response.data.data) {
          encontrou = true;
          setPedidosPendentes(response.data.data);
        } else {
          encontrou = false;
          setPedidosPendentes([]);
        }

        if (encontrou === false) {
          const response2 = await api.post(
            '/api-pedidos/ultimospedidosvendedorinternocliente',
            {
              email: vendedorlogado.email,
              codcliente: clientepedido.codigo
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
          if (response2.data.data) {
            encontrou = true;
            setPedidosPendentes(response2.data.data);
          } else {
            encontrou = false;
            setPedidosPendentes([]);
          }
        }
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Favor escolher o cliente do pedido.'
        });
        setPedidosPendentes([]);
      }

      setisFetching(false);
    } else {
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Favor escolher o cliente do pedido.'
      });
      setPedidosPendentes([]);
    }
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  return (
    <>
      <MenuTemporario isOpen={isOpen3} handleClose={handleDialogClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseMenuRapido2} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell align="right">Descrição</TableCell>
                <TableCell align="right">Quantidade Pedida</TableCell>
                <TableCell align="right">Valor Venda</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itenspedidohoje.map((row) => (
                <TableRow key={row.coditem}>
                  <TableCell component="th" scope="row">
                    {row.coditem}
                  </TableCell>
                  <TableCell align="right">{row.descitem}</TableCell>
                  <TableCell align="right">{Number(row.qtpedida)}</TableCell>
                  <TableCell align="right">
                    {' '}
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2
                    }).format(row.vlvenda) }
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell>
                  <b>
                    {
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2
                        }).format(itenspedidohoje.map((item) => (Number(item.vlvenda) * Number(item.qtpedida))).reduce((prev, next) => prev + next, 0))
                      }
                  </b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </MenuTemporario>
      <BackDrop open={isFetching} />
      <div className={classes.root}>
        <Card className={classes.cardpedidospendentes}>
          <Box
            display="flex"
            justifyContent="flex-start"
            p={1}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={GetUltimosPedidos}
            >
              Consultar
            </Button>
          </Box>
          <CardHeader title="Ultimos Pedidos" />
          <Divider />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection="desc">
                    <Tooltip
                      enterDelay={300}
                      title="Sort"
                    >
                      <TableSortLabel
                        active
                        direction="desc"
                      >
                        Data
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    Tipo
                  </TableCell>
                  <TableCell>
                    Pedido Cliente
                  </TableCell>
                  <TableCell>
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pedidospendentes.map((pedido) => (
                  <TableRow
                    hover
                    key={pedido.numpedido}
                  >
                    <TableCell>
                      {moment(pedido.datapedido).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Chip
                        avatar={<Avatar>{pedido.tipopedido.substring(0, 1)}</Avatar>}
                        label=""
                        variant="outlined"
                      />

                    </TableCell>
                    <TableCell>
                      {pedido.numpedido}
                    </TableCell>
                    <TableCell>
                      {
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2
                        }).format(pedido.itens.map((item) => (Number(item.vlvenda) * Number(item.qtpedida))).reduce((prev, next) => prev + next, 0))
                      }
                    </TableCell>
                    <TableCell>
                      <Chip
                        icon={<ListAltIcon />}
                        onClick={() => { ItensPedido2(pedido.itens); }}
                        label="Itens Pedido"
                        size="small"
                        variant="outlined"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Divider,
  Typography
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Page from 'src/components/Page';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content';
import { format } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import MUIDataTable from 'mui-datatables';
import TextMaskPercent from '../../../components/TextMaskPercent';
import api from '../../../services/api';
import BackDrop from '../../../components/BackDrop';

/* function BarraEndereco() {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="/">
        Material-UI
      </Link>
      <Link color="inherit" href="">
        Core
      </Link>
      <Typography color="textPrimary">Breadcrumb</Typography>
    </Breadcrumbs>
  );
} */

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  afastamento: {
    paddingTop: theme.spacing(3)
  },
  afastamentocard: {
    paddingBottom: theme.spacing(18)
  },
}));

const ItensCampanha = (props) => {
  const classes = useStyles();
  const { data } = props;
  const columns = [
    {
      name: 'codfamilia',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'codproduto',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'descfamilila',
      label: 'Familia',
      options: {
        filter: true
        // display: "excluded"
      }
    },
    {
      name: 'descproduto',
      label: 'Produto',
      options: {
        filter: true
        // display: "excluded"
      }
    },
    {
      name: 'tipo',
      label: 'Tipo'
    },
    {
      name: 'qt',
      label: 'Quantidade'
    },
    {
      name: 'desccomercial',
      label: 'Desconto Comercial'
    },
  ];

  const options = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    // selectableRows: 'none',
    rowsPerPage: 10,
    onRowsDelete: (rowsDeleted, d) => {
      const campanha = JSON.parse(localStorage.getItem('CAMPANHA'));
      let novalista = [];
      d.forEach((v) => {
        const iteninserido = campanha.itens.filter((it) => {
          return it.codigo === v[1];
        });
        novalista = novalista.concat(iteninserido);
      });
      campanha.itens = novalista;
      localStorage.setItem('CAMPANHA', JSON.stringify(novalista));
    },
    /* onRowClick: (rowData, rowState) => {
      console.log(rowData, rowState);
    }, */
    textLabels: {
      pagination: {
        next: 'Próximo',
        previous: 'Anterior',
        rowsPerPage: 'linhas por Pagina:',
        displayRows: 'de'
      },
      body: {
        noMatch: 'Nenhum item cadastrado',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenado por ${column.label}`
      },
      filter: {
        all: 'Todos',
        title: 'FILTRO',
        reset: 'LIMPAR'
      }
    }

  };

  return (
    <MUIDataTable
      columns={columns}
      className={classes.afastamento}
      data={data}
      options={options}
    />
  );
};

ItensCampanha.propTypes = {
  data: PropTypes.isRequired
};

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const FullWidthTabs = () => {
  const MySwal = withReactContent(Swal);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const listatipocampanha = [
    {
      value: 'Aberta',
      label: 'Campanha Aberta'
    },
    {
      value: 'Fechada',
      label: 'Campanha Fechada'
    },
    {
      value: 'Fracionada',
      label: 'Campanha Fracionada'
    },
    {
      value: 'Reativados',
      label: 'Campanha Reativados'
    }
  ];

  const formatadata = (data) => format(data, "yyyy'-'MM'-'dd");
  const [nomecampanha, setNomeCampanha] = useState('');
  const [desabilitaproduto, setDesabilitaproduto] = useState(false);
  const [desabilitafamilia, setDesabilitafamilia] = useState(false);
  const [desabilitadescontos, setDesabilitadescontos] = useState(false);
  const [comecouinserir, setcomecouinserir] = useState(false);
  const [tipocampanha, setTipoCampanha] = useState('Aberta');
  const [vtipovenda, setTipoVenda] = useState('nao');
  const [codfamilia, setCodFamilia] = useState(null);
  const [codregiao, setCodRegiao] = useState(null);
  const [coditem, setCodItem] = useState(null);
  const [vendaoubonificacao, setvendaoubonificacao] = useState([
    {
      value: 'nao',
      label: 'Venda'
    }
  ]);

  const [codcliente, setCodCliente] = useState(null);
  const [codvendedor, setCodVendedor] = useState(null);
  const [codgrliente, setCodGrupoCliente] = useState(null);
  // const [codregiao, setCodRegiao] = useState(null);
  const [codgestor, setCodGestor] = useState(null);
  const [codvendedorinterno, setCodVendedorInterno] = useState(null);
  const [codorgvenda, setcodorgvenda] = useState(null);
  const [codcanalvenda, setcodcanalvenda] = useState(null);
  const [codrede, setcodrede] = useState(null);

  const [qtitens, setQtItens] = useState(0);
  const [descontocomercial, setDescontoComercial] = useState(0);
  const [descontoadicional, setDescontoAdicional] = useState(0);
  const [produtos, setProdutos] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [regiao, setRegiao] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [organizacaovendas, setOrganizacaoVendas] = useState([]);
  const [gestores, setGestor] = useState([]);
  const [vendedoresinternos, setVendedorInterno] = useState([]);  
  const [canalvendas, setCanalVenda] = useState([]);
  const [grupoclientes, setGrupoCliente] = useState([]);
  const [redes, setRedes] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [dataretorno, setDataRetorno] = useState([]);
  const [datainicial, setDataInicial] = React.useState(new Date());
  const [isFetching, setisFetching] = useState(true);
  const [mensagem, setmensagem] = useState('Carregando Dados...');
  const [datafinal, setDataFinal] = React.useState(new Date());
  const [datainicialformatada, setdatainicialformatada] = React.useState(formatadata(new Date()));
  const [datafinalformatada, setdatafinalformatada] = React.useState(formatadata(new Date()));
  // const [produto, setProduto] = useState('');

  const getOrganizacaoVenda = async () => {
    const response = await api.get(
      '/api-pedidos/organizacaovenda',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Organização Venda');
    setOrganizacaoVendas(response.data.data);
  };

  const getGestor = async () => {
    const response = await api.get(
      '/api-pedidos/gestor',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Gestor');
    setGestor(response.data.data);
  };


  const getVendedorInterno = async () => {
    const response = await api.get(
      '/api-pedidos/vendedorinterno',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Vendedor Interno');
    setVendedorInterno(response.data.data);
  };

  const getGrupoClientes = async () => {
    const response = await api.get(
      '/api-pedidos/grupocliente',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Grupo Clientes');
    setGrupoCliente(response.data.data);
  };

  const getClientes = async () => {
    const response = await api.get(
      '/api-pedidos/emitente',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Clientes');
    setClientes(response.data.data);
  };

  const getFamilia = async () => {
    const response = await api.get(
      '/api-pedidos/familia',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Familia');
    setFamilias(response.data.data);
  };

  const getRegiao = async () => {
    const response = await api.get(
      '/api-pedidos/regiao',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Região');
    setRegiao(response.data.data);
  };

  const getProduto = async () => {
    const response = await api.get(
      '/api-pedidos/item',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Produto');
    setProdutos(response.data.data);
  };

  const getVendedores = async () => {
    const response = await api.get(
      '/api-pedidos/vendedor',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    const retornoMap = response.data.data.map((element) => {
      return { codigo: element.codigo, nome: element.nome };
    });
    setmensagem('Carregando Vendedores');
    setVendedores(retornoMap);
  };

  const getRedes = async () => {
    const response = await api.get(
      '/api-pedidos/rede',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setmensagem('Carregando Redes');
    setRedes(response.data.data);
  };

  useEffect(() => {
    setcomecouinserir(false);
    const listacanalvendas = [{
      codigo: '1',
      descricao: 'RETAIL'
    },
    {
      codigo: '2',
      descricao: 'FOOD SERVICE'
    },
    {
      codigo: '3',
      descricao: 'ATACADO/DISTRIBUIDOR'
    }];

    setvendaoubonificacao([
      {
        value: 'nao',
        label: 'Venda'
      }
    ]);
    setisFetching(true);
    getClientes();
    getRedes();
    getOrganizacaoVenda();
    getGestor();
    getVendedorInterno();
    getGrupoClientes();
    getProduto();
    getFamilia();
    getRegiao();
    getVendedores();
    setCanalVenda(listacanalvendas);

    // MOCKUP TABELA
    setDataRetorno([]);
    localStorage.setItem('CAMPANHA', null);
    setisFetching(false);
  }, []);

  const handleChange = (event, newValue) => {
    // const lista = JSON.parse(localStorage.getItem('CAMPANHA'));
    // if (lista !== null) setDataRetorno(lista.itens);
    setValue(newValue);
  };

  const handleEscolhaFamilia = (e) => {
    setCodFamilia(e);
  };

  const navigate = useNavigate();

  const verificaCampanha = (e) => {
    if (e === 'Aberta' || e === 'Fracionada') {
      setDesabilitaproduto(false);
      setTipoVenda('nao');
      setvendaoubonificacao([
        {
          value: 'nao',
          label: 'Venda'
        }
      ]);
    } else {
      setDesabilitaproduto(false);
      setvendaoubonificacao([
        {
          value: 'nao',
          label: 'Venda'
        },
        {
          value: 'sim',
          label: 'Bonificação'
        }
      ]);
    }
  };

  const TipoItem = (e) => {
    if (e) {
      if (e === 'sim') {
        setDescontoAdicional(0);
        setDescontoComercial(0);
        setDesabilitadescontos(true);
      } else {
        setDesabilitadescontos(false);
      }
      setTipoVenda(e);
    } else {
      setTipoVenda('nao');
    }
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const handleDataFinal = (e) => {
    const resultado = formatadata(e);
    setdatafinalformatada(resultado);
    setDataFinal(e);
  };

  const handleItemCombo = (e) => {
    if (e) {
      setDesabilitafamilia(true);
    } else {
      setDesabilitafamilia(false);
    }
  };

  const handleFamiliaCombo = (e) => {
    if (e) {
      setCodItem(null);
      setDesabilitaproduto(true);
    } else {
      setDesabilitaproduto(false);
    }
  };

  const handleDataInicial = (e) => {
    const resultado = formatadata(e);
    setdatainicialformatada(resultado);
    setDataInicial(e);
  };

  const handleAdicionarItem = () => {
    let dadocampanha = null;
    const itensselecionados = JSON.parse(localStorage.getItem('CAMPANHA'));
    let vpremiacao = false;
    // if (coditem === null) console.log('Nulo');

    try {
      if (nomecampanha === '' || tipocampanha === '' || vtipovenda === '' || qtitens === 0) {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Favor informar Nome da Campanha, Tipo de Venda e Quantidade'
        });
      } else {
        // VALIDACAO DO ITEM
        if (coditem !== null && coditem !== '') {
          if (itensselecionados === null) {
            let teste = [];

            if (vtipovenda === 'nao') {
              vpremiacao = false;
              teste = dataretorno.concat({
                codfamilia: '',
                descfamilila: '',
                codproduto: coditem.codigo,
                descproduto: coditem.descricao,
                tipo: 'Venda',
                qt: qtitens,
                desccomercial: descontocomercial,
                descadicional: descontoadicional
              });
            } else {
              vpremiacao = true;
              teste = dataretorno.concat({
                codfamilia: '',
                descfamilila: '',
                codproduto: coditem.codigo,
                descproduto: coditem.descricao,
                tipo: 'Bonificação',
                qt: qtitens,
                desccomercial: descontocomercial,
                descadicional: descontoadicional
              });
            }
            setDataRetorno(teste);

            dadocampanha = {
              campanha: [{
                dt_inicio: datainicialformatada,
                dt_fim: datafinalformatada,
                nome: nomecampanha,
                tipo: tipocampanha
              }],
              itens: [
                {
                  tipo: 'Item',
                  premiacao: vpremiacao,
                  codigo: coditem.codigo,
                  produto: coditem.descricao,
                  quantidade: String(qtitens),
                  desconto_comercial: String(descontocomercial),
                  desconto_adicional: String(descontoadicional)
                }
              ]
            };
          } else {
            const iteninserido = itensselecionados.itens.filter((it) => {
              return it.codigo === coditem.codigo;
            });

            const bonificacaoinserida = itensselecionados.itens.filter((it) => {
              return it.premiacao === 'sim';
            });

            if (bonificacaoinserida.length > 0) {
              MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Bonificação já inserida'
              });
            } else {
              if (iteninserido.length > 0 && tipocampanha !== 'Fracionada' && tipocampanha !== 'Fechada') {
                MySwal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Item já inserido'
                });
              } else {
                let teste = [];

                if (vtipovenda === 'nao') {
                  vpremiacao = false;
                  teste = dataretorno.concat({
                    codfamilia: '',
                    descfamilila: '',
                    codproduto: coditem.codigo,
                    descproduto: coditem.descricao,
                    tipo: 'Venda',
                    qt: qtitens,
                    desccomercial: descontocomercial,
                    descadicional: descontoadicional
                  });
                } else {
                  vpremiacao = true;
                  teste = dataretorno.concat({
                    codfamilia: '',
                    descfamilila: '',
                    codproduto: coditem.codigo,
                    descproduto: coditem.descricao,
                    tipo: 'Bonificação',
                    qt: qtitens,
                    desccomercial: descontocomercial,
                    descadicional: descontoadicional
                  });
                }
                setDataRetorno(teste);
                itensselecionados.itens.push(
                  {
                    tipo: 'Item',
                    premiacao: vpremiacao,
                    codigo: coditem.codigo,
                    produto: coditem.descricao,
                    quantidade: String(qtitens),
                    desconto_comercial: String(descontocomercial),
                    desconto_adicional: String(descontoadicional)
                  }
                );
              }

              dadocampanha = {
                campanha: [{
                  dt_inicio: datainicialformatada,
                  dt_fim: datafinalformatada,
                  nome: nomecampanha,
                  tipo: tipocampanha
                }],
                itens: itensselecionados.itens
              };
            }
          }
        } else if (codfamilia !== null) { // VALIDACAO DA FAMILIA
          if (itensselecionados === null) {
            let teste = [];

            if (vtipovenda === 'nao') {
              vpremiacao = false;
              teste = dataretorno.concat({
                codfamilia: codfamilia.codfamilia,
                descfamilila: codfamilia.descricao,
                codproduto: '',
                descproduto: '',
                tipo: 'Venda',
                qt: qtitens,
                desccomercial: descontocomercial,
                descadicional: descontoadicional
              });
            } else {
              vpremiacao = true;
              teste = dataretorno.concat({
                codfamilia: codfamilia.codfamilia,
                descfamilila: codfamilia.descricao,
                codproduto: '',
                descproduto: '',
                tipo: 'Bonificação',
                qt: qtitens,
                desccomercial: descontocomercial,
                descadicional: descontoadicional
              });
            }
            setDataRetorno(teste);

            dadocampanha = {
              campanha: [{
                dt_inicio: datainicialformatada,
                dt_fim: datafinalformatada,
                nome: nomecampanha,
                tipo: tipocampanha
              }],
              itens: [
                {
                  tipo: 'Familia',
                  premiacao: vpremiacao,
                  codigo: codfamilia.codfamilia,
                  produto: codfamilia.descricao,
                  quantidade: String(qtitens),
                  desconto_comercial: String(descontocomercial),
                  desconto_adicional: String(descontoadicional)
                }
              ]
            };
          } else {
            const iteninserido = itensselecionados.itens.filter((it) => {
              return it.codigo === codfamilia.codfamilia;
            });

            const bonificacaoinserida = itensselecionados.itens.filter((it) => {
              return it.premiacao === 'sim';
            });

            if (bonificacaoinserida.length > 0) {
              MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Bonificação já inserida'
              });
            } else {
              if (iteninserido.length > 0 && tipocampanha !== 'Fracionada') {
                MySwal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Familia já inserida'
                });
              } else {
                let teste = [];

                if (vtipovenda === 'nao') {
                  vpremiacao = false;
                  teste = dataretorno.concat({
                    codfamilia: codfamilia.codfamilia,
                    descfamilila: codfamilia.descricao,
                    codproduto: '',
                    descproduto: '',
                    tipo: 'Venda',
                    qt: qtitens,
                    desccomercial: descontocomercial,
                    descadicional: descontoadicional
                  });
                } else {
                  vpremiacao = true;
                  teste = dataretorno.concat({
                    codfamilia: codfamilia.codfamilia,
                    descfamilila: codfamilia.descricao,
                    codproduto: '',
                    descproduto: '',
                    tipo: 'Bonificação',
                    qt: qtitens,
                    desccomercial: descontocomercial,
                    descadicional: descontoadicional
                  });
                }
                setDataRetorno(teste);
                itensselecionados.itens.push(
                  {
                    tipo: 'Familia',
                    premiacao: vpremiacao,
                    codigo: codfamilia.codfamilia,
                    produto: codfamilia.descricao,
                    quantidade: String(qtitens),
                    desconto_comercial: String(descontocomercial),
                    desconto_adicional: String(descontoadicional)
                  }
                );
              }

              dadocampanha = {
                campanha: [{
                  dt_inicio: datainicialformatada,
                  dt_fim: datafinalformatada,
                  nome: nomecampanha,
                  tipo: tipocampanha
                }],
                itens: itensselecionados.itens
              };
            }
          }
        }

        localStorage.setItem('CAMPANHA', JSON.stringify(dadocampanha));
      }
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Favor informar Item ou Familia'
      });
    }
    setCodFamilia('');
    setCodRegiao('');
    setCodItem('');
    setQtItens(0);
    setDescontoComercial(0);
    setDescontoAdicional(0);
  };

  const handleSalvarCampanha = async () => {
    const aclientes = [];
    const avendedores = [];
    const agrupoclientes = [];
    const agestores = [];
    const avendedoresinternos = [];
    const acanalvenda = [];
    const aregiao = [];
    const aorganizacaovenda = [];
    const aredes = [];
    // let a_vendedores = [];
    // CLIENTE
    if (codcliente) {
      for (let i = 0; i < codcliente.length; i++) {
        aclientes.push(codcliente[i].fantasia);
      }
    } else {
      aclientes.push('ALL');
    }
    // VENDEDOR
    if (codvendedor) {
      for (let i = 0; i < codvendedor.length; i++) {
        avendedores.push(codvendedor[i].nome);
      }
    } else {
      avendedores.push('ALL');
    }
    // VENDEDOR
    if (codvendedorinterno) {
      for (let i = 0; i < codvendedorinterno.length; i++) {
        avendedoresinternos.push(codvendedorinterno[i].nome);
      }
    } else {
      avendedoresinternos.push('ALL');
    }
    // GRUPO CLIENTE
    if (codgrliente) {
      for (let i = 0; i < codgrliente.length; i++) {
        agrupoclientes.push(codgrliente[i].descricao);
      }
    } else {
      agrupoclientes.push('ALL');
    }
    // REGIAO
    if (codregiao) {
      for (let i = 0; i < codregiao.length; i++) {
        aregiao.push(codregiao[i].descricao);
      }
    } else {
      aregiao.push('ALL');
    }
    // GESTORES
    if (codgestor) {
      for (let i = 0; i < codgestor.length; i++) {
        agestores.push(codgestor[i].nome);
      }
    } else {
      agestores.push('ALL');
    }
    // CANAL VENDA
    if (codcanalvenda) {
      for (let i = 0; i < codcanalvenda.length; i++) {
        acanalvenda.push(codcanalvenda[i].descricao);
      }
    } else {
      acanalvenda.push('ALL');
    }
    // ORGANIZACAO VENDA
    if (codorgvenda) {
      for (let i = 0; i < codorgvenda.length; i++) {
        aorganizacaovenda.push(codorgvenda[i].codigo);
      }
    } else {
      aorganizacaovenda.push('ALL');
    }
    // REDES
    if (codrede) {
      for (let i = 0; i < codrede.length; i++) {
        aredes.push(codrede[i].descricao);
      }
    } else {
      aredes.push('ALL');
    }

    if (dataretorno.length === 0) {
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Favor inserir itens a campanha'
      });
    } else {
      const itensselecionados = JSON.parse(localStorage.getItem('CAMPANHA'));
      const itemvendainserido = itensselecionados.itens.filter((it) => {
        return it.premiacao === false;
      });

      if (itemvendainserido.length === 0) {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Item de venda não inserido'
        });
      } else {
        itensselecionados.a_cliente = aclientes;
        itensselecionados.a_vendedor = avendedores;
        itensselecionados.a_vendedorinterno = avendedoresinternos;
        itensselecionados.a_grupoclientes = agrupoclientes;
        itensselecionados.a_gestores = agestores;
        itensselecionados.a_canalvenda = acanalvenda;
        itensselecionados.a_regiao = aregiao;
        itensselecionados.a_organizacaovenda = aorganizacaovenda;
        itensselecionados.a_redes = aredes;
        const response = await api.post(
          '/api-pedidos/novacampanha',
          itensselecionados,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        if (response.data.message === 'ok') {
          MySwal.fire({
            icon: 'success',
            title: 'Oops...',
            text: 'Campanha salva'
          });
          navigate('/app/campanha');
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Campanha já existe'
          });
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <BackDrop open={isFetching} texto={mensagem} />

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Campanha" {...a11yProps(0)} />
          <Tab label="Item" {...a11yProps(1)} />
          <Tab label="Aplicação" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <div style={{ display: value === 0 ? 'block' : 'none' }}>
        <Card>
          <CardHeader
            subheader=""
            title=""
          />
          <CardContent>
            <Grid
              container
              spacing={3}
            >

              <Grid
                item
                md={6}
                xs={12}
              >

                <TextField
                  fullWidth
                  label="Nome da Campanha"
                  name="nomecampanha"
                  disabled={comecouinserir}
                  value={nomecampanha}
                  required
                  variant="outlined"
                  onChange={(e) => setNomeCampanha(e.target.value)}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Tipo"
                  name="state"
                  disabled={comecouinserir}
                  required
                  select
                  SelectProps={{ native: true }}
                  onChange={(e) => {
                    setTipoCampanha(e.target.value);
                    verificaCampanha(e.target.value);
                  }}
                  variant="outlined"
                >
                  {listatipocampanha.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={ptLocale}
                >
                  <DatePicker
                    margin="normal"
                    label="Data Inicial"
                    inputVariant="outlined"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    disabled={comecouinserir}
                    minDate={new Date()}
                    value={datainicial}
                    onChange={handleDataInicial}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>

              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={ptLocale}
                >
                  <DatePicker
                    margin="normal"
                    inputVariant="outlined"
                    label="Data Final"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    disabled={comecouinserir}
                    minDate={datainicial}
                    value={datafinal}
                    onChange={handleDataFinal}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <div style={{ display: value === 1 ? 'block' : 'none' }}>
        <Card>
          <CardHeader
            subheader=""
            title=""
          />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Tipo"
                  name="state"
                  required
                  select
                  SelectProps={{ native: true }}
                  onChange={(e) => TipoItem(e.target.value)}
                  variant="outlined"
                >
                  {vendaoubonificacao.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>

              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Autocomplete
                  id="familiacombo"
                  options={familias}
                  value={codfamilia}
                  hidden={desabilitafamilia}
                  getOptionLabel={(option) => option.descricao}
                  style={{ width: 450 }}
                  onChange={(event, e) => {
                    handleEscolhaFamilia(e);
                    handleFamiliaCombo(e);
                  }}
                  renderInput={(params) => <TextField {...params} label="Familia*" variant="outlined" />}
                />

              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Autocomplete
                  id="itemcombo"
                  hidden={desabilitaproduto}
                  options={produtos}
                  value={coditem}
                  getOptionLabel={(option) => option.descricao}
                  style={{ width: 450 }}
                  onChange={(event, e) => {
                    setCodItem(e);
                    handleItemCombo(e);
                  }}
                  renderInput={(params) => <TextField {...params} label="Produto*" variant="outlined" />}
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  fullWidth
                  type="number"
                  label="Qt. Itens"
                  value={qtitens}
                  name="qtitens"
                  required
                  variant="outlined"
                  onChange={(e) => setQtItens(e.target.value)}
                />

              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                {' '}
                <TextField
                  label="Desconto Comercial"
                  value={descontocomercial}
                  // eslint-disable-next-line react/jsx-boolean-value
                  disabled={desabilitadescontos}
                  onChange={(e) => setDescontoComercial(e.target.value)}
                  name="descontocomercial"
                  id="descontocomercial"
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskPercent
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Divider />
        <Card>
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleAdicionarItem}
            >
              Adicionar
            </Button>
          </Box>
          <ItensCampanha data={dataretorno} />
        </Card>
      </div>
      <div style={{ display: value === 2 ? 'block' : 'none' }}>
        <Card className={classes.afastamentocard}>
          <CardHeader
            subheader=""
            title=""
          />
          <CardContent>

            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography variant="body1" gutterBottom>Cliente</Typography>
                <Select
                  isMulti
                  getOptionLabel={(option) => option.fantasia}
                  getOptionValue={(option) => option.codigo}
                  onChange={(v) => setCodCliente(v)}
                  options={clientes}
                />

              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography variant="body1" gutterBottom>Vendedor</Typography>
                <Select
                  isMulti
                  getOptionLabel={(option) => option.nome}
                  getOptionValue={(option) => option.codigo}
                  onChange={(v) => setCodVendedor(v)}
                  options={vendedores}
                />

              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography variant="body1" gutterBottom>Vendedor Interno</Typography>
                <Select
                  isMulti
                  getOptionLabel={(option) => option.nome}
                  getOptionValue={(option) => option.codigo}
                  onChange={(v) => setCodVendedorInterno(v)}
                  options={vendedoresinternos} 
                />

              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body1" gutterBottom>Grupo</Typography>
                <Select
                  isMulti
                  getOptionLabel={(option) => option.descricao}
                  getOptionValue={(option) => option.codigo}
                  onChange={(v) => setCodGrupoCliente(v)}
                  options={grupoclientes}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body1" gutterBottom>Região</Typography>
                <Select
                  isMulti
                  getOptionLabel={(option) => option.descricao}
                  getOptionValue={(option) => option.descricao}
                  onChange={(v) => setCodRegiao(v)}
                  options={regiao}
                />
              </Grid>

              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body1" gutterBottom>Gestor</Typography>
                <Select
                  isMulti
                  getOptionLabel={(option) => option.nome}
                  getOptionValue={(option) => option.codigo}
                  onChange={(v) => setCodGestor(v)}
                  options={gestores}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body1" gutterBottom>Organização de venda</Typography>
                <Select
                  isMulti
                  getOptionLabel={(option) => option.codigo}
                  getOptionValue={(option) => option.codigo}
                  onChange={(v) => setcodorgvenda(v)}
                  options={organizacaovendas}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body1" gutterBottom>Canal de vendas</Typography>
                <Select
                  isMulti
                  getOptionLabel={(option) => option.descricao}
                  getOptionValue={(option) => option.codigo}
                  onChange={(v) => setcodcanalvenda(v)}
                  options={canalvendas}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body1" gutterBottom>Rede</Typography>
                <Select
                  isMulti
                  getOptionLabel={(option) => option.descricao}
                  getOptionValue={(option) => option.codigo}
                  onChange={(v) => setcodrede(v)}
                  options={redes}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>

      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleSalvarCampanha}
        >
          Salvar
        </Button>
      </Box>
    </div>
  );
};

const NovaCampanha = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Campanha"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          <FullWidthTabs />
        </Box>
      </Container>
    </Page>
  );
};

export default NovaCampanha;

import React from 'react';
import {
  Box,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StoreIcon from '@material-ui/icons/Store';
import GridItensPedido2 from './GridItensPedido2';
import SelecaoCampanha from './SelecaoCampanha';
import UltimosPedidos from './UltimosPedidos';
import FormPedido from './FormPedido';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    width: '100%',
    minHeight: '100%',
  },
  stickToBottom: {
    backgroundColor: theme.palette.background.dark,
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
}));

const NovoPedidoView = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (
    <div>
      <Page
        className={classes.root}
        title="Pedido"
      >

        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
        >
          <BottomNavigationAction label="Pedido" icon={<ReceiptIcon />} {...a11yProps(0)} />
          <BottomNavigationAction label="Produtos" icon={<ShoppingBasketIcon />} {...a11yProps(1)} />
          <BottomNavigationAction label="Campanha" icon={<StoreIcon {...a11yProps(2)} />} />
          <BottomNavigationAction label="Ultimos Pedidos" icon={<StoreIcon {...a11yProps(3)} />} />
        </BottomNavigation>
        <div style={{ display: value === 0 ? 'block' : 'none' }}>
          <FormPedido />
        </div>
        <div style={{ display: value === 1 ? 'block' : 'none' }}>
          <Box mt={3}>
            <GridItensPedido2 />

          </Box>
        </div>
        <div style={{ display: value === 2 ? 'block' : 'none' }}>

          <SelecaoCampanha />

        </div>
        <div style={{ display: value === 3 ? 'block' : 'none' }}>

          <UltimosPedidos />

        </div>
      </Page>
    </div>
  );
};

export default NovoPedidoView;

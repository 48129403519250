import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import api from '../../../services/api';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ItensDetalheCampanha = ({ className, ...rest }) => {
  const classes = useStyles();
  const [detalhecampanha, setDetalheCampanha] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const idcampanha = localStorage.getItem('IDCAMPANHA');
      const response = await api.get(
        `/pedidos-sales/v2/pedido/campanha/${idcampanha}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setDetalheCampanha(response.data);
    }
    fetchData();
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      {detalhecampanha
        && (
          <div>
            <Card>
              <CardContent>
                <Paper>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <Typography variant="h3" gutterBottom>
                        {detalhecampanha.nome}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <Typography variant="h3" gutterBottom>
                        Vigência:
                        {' '}
                        {detalhecampanha.vigencia}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Chip color="primary" variant="outlined" label={`Status: ${detalhecampanha.vencida}`} />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Chip variant="outlined" label={`Campanha ${detalhecampanha.tipo}`} />
                    </Grid>
                    <Divider />
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Typography variant="h4" gutterBottom>
                        Produtos Participantes
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table">
                          <caption>Produtos partipantes da campanha</caption>
                          <TableHead>
                            <TableRow>
                              <TableCell>Produto</TableCell>
                              <TableCell align="right">Quantidade</TableCell>
                              <TableCell align="right">Desconto Comercial</TableCell>
                              <TableCell align="right" />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {detalhecampanha.itens.map((row) => (
                              <TableRow key={row.codigo}>
                                <TableCell align="left">{row.produto}</TableCell>
                                <TableCell align="right">{row.quantidade}</TableCell>
                                <TableCell align="right">
                                  {row.desconto_comercial}
                                  {' '}
                                  %
                                </TableCell>
                                <TableCell align="right"><Chip variant="outlined" label={`${row.bonificacao}`} color={`${row.cor}`} avatar={<Avatar>V</Avatar>} /></TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Typography variant="h4" gutterBottom>
                        Regras
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Typography variant="h5" gutterBottom>
                        Cliente
                      </Typography>

                      <List>

                        {detalhecampanha.aplicacao.a_cliente.map((campanha, i) => (
                          <ListItem
                            divider={i < detalhecampanha.aplicacao.a_cliente.length - 1}
                            key={campanha}
                          >
                            <ListItemText
                              primary={campanha}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Typography variant="h5" gutterBottom>
                        Vendedor
                      </Typography>

                      <List>

                        {detalhecampanha.aplicacao.a_vendedor.map((campanha, i) => (
                          <ListItem
                            divider={i < detalhecampanha.aplicacao.a_vendedor.length - 1}
                            key={campanha}
                          >
                            <ListItemText
                              primary={campanha}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Typography variant="h5" gutterBottom>
                        Grupo Clientes
                      </Typography>

                      <List>

                        {detalhecampanha.aplicacao.a_grupoclientes.map((campanha, i) => (
                          <ListItem
                            divider={i < detalhecampanha.aplicacao.a_grupoclientes.length - 1}
                            key={campanha}
                          >
                            <ListItemText
                              primary={campanha}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Typography variant="h5" gutterBottom>
                        Gestores
                      </Typography>

                      <List>

                        {detalhecampanha.aplicacao.a_gestores.map((campanha, i) => (
                          <ListItem
                            divider={i < detalhecampanha.aplicacao.a_gestores.length - 1}
                            key={campanha}
                          >
                            <ListItemText
                              primary={campanha}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Typography variant="h5" gutterBottom>
                        Canal Venda
                      </Typography>

                      <List>

                        {detalhecampanha.aplicacao.a_canalvenda.map((campanha, i) => (
                          <ListItem
                            divider={i < detalhecampanha.aplicacao.a_canalvenda.length - 1}
                            key={campanha}
                          >
                            <ListItemText
                              primary={campanha}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Typography variant="h5" gutterBottom>
                        Região
                      </Typography>

                      <List>

                        {detalhecampanha.aplicacao.a_regiao.map((campanha, i) => (
                          <ListItem
                            divider={i < detalhecampanha.aplicacao.a_regiao.length - 1}
                            key={campanha}
                          >
                            <ListItemText
                              primary={campanha}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Typography variant="h5" gutterBottom>
                        Organização Venda
                      </Typography>

                      <List>

                        {detalhecampanha.aplicacao.a_organizacaovenda.map((campanha, i) => (
                          <ListItem
                            divider={i < detalhecampanha.aplicacao.a_organizacaovenda.length - 1}
                            key={campanha}
                          >
                            <ListItemText
                              primary={campanha}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Typography variant="h5" gutterBottom>
                        Redes
                      </Typography>

                      <List>

                        {detalhecampanha.aplicacao.a_redes.map((campanha, i) => (
                          <ListItem
                            divider={i < detalhecampanha.aplicacao.a_redes.length - 1}
                            key={campanha}
                          >
                            <ListItemText
                              primary={campanha}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>

                  </Grid>
                </Paper>
              </CardContent>
            </Card>
          </div>
        )}
    </form>
  );
};

ItensDetalheCampanha.propTypes = {
  className: PropTypes.string
};

export default ItensDetalheCampanha;

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}));

const getMetaRealizada = () => {
  const meta = JSON.parse(localStorage.getItem('META'));
  // let resultado = 0;
  // resultado = meta.map((li) => Number(String(li.vlrealizado).replace(',', '.'))).reduce((sum, val) => sum + val, 0);
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(meta[0].vlrealizado.replace(',', '.'));
};

const TotalProfit = ({ className, ...rest }) => {
  const classes = useStyles();
  const [vlrealizada, setVlrealizada] = useState(0);
  useEffect(() => {
    //setVlrealizada(getMetaRealizada);
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              META REALIZADA
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {vlrealizada}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalProfit.propTypes = {
  className: PropTypes.string
};

export default TotalProfit;
